import React, { useState } from "react";
import { noLogo, product, viewIcon } from "../../assets/img";
import { collaborateList, Compact_table } from "../../redux/api/DummyJson";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeletePopup from "../Popup/DeletePopup";
import ViewStockPoppup from "./ViewStockPoppup";
import { useDispatch } from "react-redux";
import { saveAlertMessage } from "../../redux/slice/alertMessage";
import { useOrderChangeProjectDetailsMutation } from "../../redux/api/api";
import { saveLoader } from "../../redux/slice/loaderNoti";

const TableComp2 = ({
  setAddTitle,
  viewStockToggle,
  deleteToggle,
  toggleBtn1,
  tab,
  projectDetail,
  setProjectDetail,
  setShowPopup,
  estimateStatus,
  setStatus,
  setDeletes,
  deletes,
  projectData,
  billingAddress,
  setPopupShow,
  getTitle,
  deletePopupOpenTitle,
  setDisplayBtn,
  displayBtn,
  getProjectView,
  setStatus3,
  setTitleDect,
  titleDect,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hide, setHide] = useState(false);
  const [actionBtn, setActionBtn] = useState(null);

  // RTK QUERY
  const [orderChangeProApi] = useOrderChangeProjectDetailsMutation();

  const handleStatus = (value) => {
    if (actionBtn == value) {
      setActionBtn(null);
    } else {
      setActionBtn(value);
    }
  };

  const deletePopupOpen = (id) => {
    setStatus(id);
    setDeletes(!deletes);
  };

  const navigateNavi = (item) => {
    // if (billingAddress?.id) {
    navigate("/estimate-edit-item", {
      state: {
        data: item,
        type: "edit",
        list: projectData,
      },
    });
    // } else {
    //   dispatch(saveAlertMessage("Add Billing Address Before Edit"));
    // }
  };

  //save reference for dragItem and dragOverItem
  const dragItem = React.useRef(null);
  const dragOverItem = React.useRef(null);

  //const handle drag sorting
  const handleSort = () => {
    //duplicate items
    let navigateItems = [...projectDetail];

    //remove and save the dragged item content
    const draggedItemContent = navigateItems.splice(dragItem.current, 1)[0];

    //switch the position
    navigateItems.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    //update the actual array
    setProjectDetail(navigateItems);
    setDisplayBtn(true);
  };

  const orderChangePro = () => {
    let formdata = new FormData();

    projectDetail?.map((item, ind) => {
      formdata.append(`orders[${ind}][project_presentation_id]`, item?.id);
      formdata.append(`orders[${ind}][order]`, ind + 1);
    });

    dispatch(saveLoader(true));
    orderChangeProApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setDisplayBtn(false);
        getProjectView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div className="presentaion_pages overflow-scroll mt-4 w-100">
      <div className="w-100 overflow-scroll table_border menu-table mt-3">
        <table className="w-100 presen-tables">
          <thead className="presen-table">
            <tr className="bg-soft-gray1">
              <th style={{ width: "600px" }} className="py-3">
                <div className="d-flex ac-jb">
                  <div className="dropdown mx-2 d-flex- flex-column ac-js d-flex w-100">
                    {projectData?.estimation_ready == 0 ? (
                      <button
                        className="dropdown-toggle align-self-start cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => setPopupShow(true)}
                      >
                        + Add
                      </button>
                    ) : (
                      <span>&nbsp;</span>
                    )}
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <span
                          className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                          onClick={() => navigate("/product-library")}
                        >
                          Product
                        </span>
                      </li>
                      <li>
                        <span
                          className="dropdown-item f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                          onClick={() => setAddTitle(true)}
                        >
                          Tittle
                        </span>
                      </li>
                    </ul>
                  </div>
                  {displayBtn && (
                    <button
                      className={`cust-btn addbtn  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded
                       `}
                      onClick={() => orderChangePro()}
                    >
                      Change Order
                    </button>
                  )}
                </div>
              </th>
              <th className="text-start px-3">Qty</th>
              <th className="text-start px-3 text-nowrap">Price Per Pack</th>
              <th className="text-start px-3">Discount</th>
              <th className="text-start px-3 text-nowrap">
                Price After Discount
              </th>
              <th className="text-start px-3 text-nowrap">Total Before Tax</th>
              <th className="text-start px-3">HSN</th>
              <th className="text-start px-3">Tax</th>
              <th className="text-start px-3 text-nowrap">Tax Value</th>
              <th className="text-start px-3 text-nowrap">Total After Tax</th>
            </tr>
          </thead>
          {projectDetail?.map((item, ind) => {
            // let sub_total_return = item?.project_detail_id?.discount_amount
            //   ? item?.project_detail_id?.net_price -
            //     item?.project_detail_id?.discount_amount
            //   : item?.project_detail_id?.net_price;

            let sub_total_return = +item?.project_detail_id?.net_price;

            if (item?.project_detail_id?.extra_charges?.length > 0) {
              item?.project_detail_id?.extra_charges?.map((items, inds) => {
                if (+items?.client_price == 1) {
                  sub_total_return += +items?.final_amount;
                } else if (+items?.client_price == 2) {
                  sub_total_return += +items?.final_amount;
                }
              });
            }

            // FOR WITHOUT EXTRA CHARGE
            let dis_without_percen = "";
            let before_without_price = item?.project_detail_id?.retail_price;
            if (item?.project_detail_id?.after_discount > 0) {
              let discountPercentage =
                ((+item?.project_detail_id?.price_per_pack -
                  +item?.project_detail_id?.after_discount) /
                  +item?.project_detail_id?.price_per_pack) *
                100;
              dis_without_percen = Number(discountPercentage).toFixed(2) + "%";
            }

            // QTY PRICES
            const getPrices = (qty, price_data) => {
              if (+qty >= 1 && +qty <= 25) {
                return price_data?.for_25;
              } else if (+qty >= 26 && +qty <= 50) {
                return price_data?.for_50;
              } else if (+qty >= 51 && +qty <= 100) {
                return price_data?.for_100;
              } else if (+qty >= 101 && +qty <= 150) {
                return price_data?.for_150;
              } else if (+qty >= 151 && +qty <= 250) {
                return price_data?.for_250;
              } else if (+qty >= 251 && +qty <= 500) {
                return price_data?.for_500;
              } else if (+qty >= 501 && +qty <= 1000) {
                return price_data?.for_1000;
              } else if (+qty > 1000) {
                return price_data?.for_1000;
              } else {
                return price_data?.for_25;
              }
            };

            // REQUEST BASED PRICE
            let request_type = 0;
            let request_type_sum = 0;

            if (
              projectData?.project_request?.request_type == 3 &&
              item?.project_detail_id?.qty
            ) {
              let price_sum = getPrices(
                +projectData?.project_request?.no_of_packs *
                  +item?.project_detail_id?.qty,
                item?.project_detail_id?.productPrice
              );
              request_type = Number(+price_sum).toFixed(2);
              request_type_sum = Number(
                +item?.project_detail_id?.qty * +price_sum
              ).toFixed(2);
            } else if (projectData?.project_request?.request_type) {
              let price_sum = getPrices(
                +projectData?.project_request?.no_of_packs *
                  +item?.project_detail_id?.qty,
                item?.project_detail_id?.productPrice
              );
              request_type = Number(+price_sum).toFixed(2);
              request_type_sum = Number(
                +projectData?.project_request?.no_of_packs * +price_sum
              ).toFixed(2);
            }

            return item?.title ? (
              <tbody
                className={`pres-body border-bottom ${
                  item?.project_detail_id?.status == 3
                    ? "opacity-50"
                    : item?.project_detail_id?.status == 4
                    ? "opacity-50"
                    : ""
                }`}
                key={ind}
                draggable
                onDragStart={(e) => (dragItem.current = ind)}
                onDragEnter={(e) => (dragOverItem.current = ind)}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
              >
                <tr className="position-relative color-for-hecd ">
                  <td>
                    <div
                      className="ps-3"
                      style={{ width: "550px", height: "80px" }}
                    >
                      <div>
                        <div className="d-flex cp">
                          <h5 className="f3 mt-2">{item?.title}</h5>
                        </div>

                        <div className="d-flex mt-3 mb-3">
                          <button
                            className="text-nowrap px-2 "
                            onClick={() => getTitle(item)}
                            disabled={
                              projectData?.estimation_ready == 0 ? false : true
                            }
                          >
                            Edit
                          </button>

                          <button
                            className="text-nowrap px-2  del-colo-d"
                            onClick={() => {
                              deletePopupOpenTitle(item?.id);
                            }}
                            disabled={
                              projectData?.estimation_ready == 0 ? false : true
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td style={{ width: "200px" }} className="text-center"></td>
                  <td style={{ width: "200px" }} className="text-center"></td>
                  <td style={{ width: "200px" }} className="text-center"></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            ) : (
              <tbody
                className={`pres-body color-for-hecd_new position-relative color-for-hecd bor_t ${
                  item?.project_detail_id?.estimate_status == 4
                    ? "opacity-50"
                    : item?.project_detail_id?.estimate_status == 5
                    ? "opacity-50"
                    : ""
                }`}
                key={ind}
                draggable
                onDragStart={(e) => (dragItem.current = ind)}
                onDragEnter={(e) => (dragOverItem.current = ind)}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
              >
                <tr className="position-relative color-for-hecd ">
                  <td
                    rowspan={
                      item?.project_detail_id?.extra_charges?.length > 0
                        ? item?.project_detail_id?.extra_charges?.length + 1
                        : 2
                    }
                    className=""
                  >
                    <div className="ps-3" style={{ width: "450px" }}>
                      <div>
                        <div className="d-flex cp">
                          <div className="productsImg">
                            <img
                              src={
                                item?.prject_img_url
                                  ? item?.prject_img_url
                                  : noLogo
                              }
                              className="pre-products-img mt-2"
                            />
                          </div>
                          <div className="d-flex flex-column mt-4 cp mx-2">
                            <span className="cp text-start mx-2 text-info f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1 ">
                              {item?.project_detail_id?.product?.name}
                            </span>

                            <span className="f3 text-start mx-2 mt-1 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14">
                              {item?.project_detail_id?.product?.description}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex mt-3 mb-3">
                          <button
                            className="text-nowrap px-2"
                            onClick={() => {
                              if (
                                item?.project_detail_id?.estimate_status == 4
                              ) {
                                estimateStatus(item?.project_detail_id?.id, 1);
                              } else if (
                                item?.project_detail_id?.estimate_status == 5
                              ) {
                                estimateStatus(item?.project_detail_id?.id, 2);
                              } else if (item?.project_detail_id?.status == 1) {
                                estimateStatus(item?.project_detail_id?.id, 4);
                              } else if (item?.project_detail_id?.status == 2) {
                                estimateStatus(item?.project_detail_id?.id, 5);
                              }
                            }}
                          >
                            {item?.project_detail_id?.estimate_status == 4
                              ? "Unhide"
                              : item?.project_detail_id?.estimate_status == 5
                              ? "Unhide"
                              : "Hide"}
                          </button>
                          <button
                            className="text-nowrap px-2 "
                            onClick={() =>
                              navigateNavi(item?.project_detail_id)
                            }
                            disabled={
                              projectData?.estimation_ready == 0 ? false : true
                            }
                          >
                            Edit
                          </button>

                          <button
                            className="text-nowrap px-2  del-colo-d"
                            onClick={() => {
                              deletePopupOpen(item?.project_detail_id?.id);
                            }}
                            disabled={
                              projectData?.estimation_ready == 0 ? false : true
                            }
                          >
                            Delete
                          </button>

                          <button
                            className="text-nowrap px-2"
                            onClick={viewStockToggle}
                          >
                            View Stock
                          </button>
                        </div>
                      </div>
                      <div
                        className="status_btn w-auto "
                        style={{
                          bottom: 0,
                        }}
                      >
                        <div
                          className={`${
                            item?.project_detail_id?.estimate_status == 0
                              ? "process_btn"
                              : item?.project_detail_id?.estimate_status == 1
                              ? "process_btn"
                              : item?.project_detail_id?.estimate_status == 2
                              ? "accepted_btn_prime"
                              : item?.project_detail_id?.estimate_status == 6
                              ? "accepted_btn"
                              : ""
                          } rounded-3 d-flex mb-2 ac-jc px-4 py-2 position-relative`}
                          style={{ width: "140px" }}
                          role={"button"}
                          // onClick={() => handleStatus(ind)}
                        >
                          <p className="text_btn text-nowrap">
                            {item?.project_detail_id?.estimate_status == 0
                              ? "In Process"
                              : item?.project_detail_id?.estimate_status == 1
                              ? "In Process"
                              : item?.project_detail_id?.estimate_status == 2
                              ? "Ready To Buy"
                              : item?.project_detail_id?.estimate_status == 6
                              ? "Payment Completed"
                              : ""}
                          </p>
                          {ind == actionBtn && (
                            <div
                              className="action_btn ml-1 position-absolute"
                              style={{
                                width: "140px",
                                zIndex: 300,
                                top: "110%",
                                left: 0,
                              }}
                            >
                              <ul
                                style={{
                                  border: "1px solid #6c757d",
                                  borderRadius: "3px",
                                }}
                              >
                                <li
                                  className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                                  onClick={() => {
                                    // presentationStatus(item?.id, 1);
                                    setActionBtn(null);
                                  }}
                                >
                                  In Process
                                </li>

                                <li
                                  className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                                  onClick={() => {
                                    // presentationStatus(item?.id, 2);
                                    setActionBtn(null);
                                  }}
                                >
                                  Ready
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-start"></td>
                  <td className="text-start"></td>
                  <td className="text-start"></td>
                  <td className="text-start"></td>
                  <td className="text-start"></td>
                  <td className="text-start"></td>
                  <td className="text-start"></td>
                  <td className="text-start"></td>
                  <td className="text-start"></td>
                </tr>

                {item?.project_detail_id?.extra_charges?.length > 0 ? (
                  item?.project_detail_id?.extra_charges?.map(
                    (charge, eInd) => {
                      return (
                        <tr className="rowspan" key={eInd}>
                          <td className="text-start mb-3  px-3">
                            {item?.project_detail_id?.qty ? (
                              item?.project_detail_id?.qty
                            ) : (
                              <span className="mx-4">-</span>
                            )}
                          </td>
                          <td className="text-start mb-3  px-3">
                            {" "}
                            {charge?.price_per_pack ? (
                              "₹" +
                              commaValue(
                                Number(charge?.price_per_pack).toFixed(2)
                              )
                            ) : (
                              <span className="mx-4">-</span>
                            )}
                          </td>
                          <td className="text-start mb-3  px-3">
                            {charge?.dis_percen ? (
                              charge?.dis_percen + "%"
                            ) : (
                              <span className="mx-4">-</span>
                            )}
                          </td>
                          <td className="text-center mb-3 px-3">
                            {charge?.net_price ? (
                              "₹" +
                              commaValue(Number(charge?.net_price).toFixed(2))
                            ) : (
                              <span className="mx-4">-</span>
                            )}
                          </td>
                          <td className="text-start mb-3 px-3">
                            {charge?.final_amount ? (
                              "₹" +
                              commaValue(
                                Number(charge?.final_amount).toFixed(2)
                              )
                            ) : (
                              <span className="mx-4">-</span>
                            )}
                          </td>
                          <td className="text-start mb-3  px-3">
                            {item?.project_detail_id?.hsn_code ? (
                              item?.project_detail_id?.hsn_code
                            ) : (
                              <span className="mx-4">-</span>
                            )}
                          </td>
                          <td className="text-start mb-3  px-3">
                            {charge?.tax ? (
                              charge?.tax + "%"
                            ) : (
                              <span className="mx-4">-</span>
                            )}
                          </td>

                          <td className="text-start mb-3  px-3">
                            {charge?.tax_value ? (
                              "₹" +
                              commaValue(Number(charge?.tax_value).toFixed(2))
                            ) : (
                              <span className="mx-4">-</span>
                            )}
                          </td>
                          <td className="text-start mb-3  px-3">
                            {charge?.tax_amount ? (
                              "₹" +
                              commaValue(Number(charge?.tax_amount).toFixed(2))
                            ) : (
                              <span className="mx-4">-</span>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : item?.project_detail_id?.projectPresentationPrice?.length >
                  0 ? (
                  <tr></tr>
                ) : (
                  <tr className="rowspan">
                    <td className="text-start mb-3 px-3">
                      {item?.project_detail_id?.qty &&
                      item?.project_detail_id?.retail_price > 0
                        ? item?.project_detail_id?.qty
                        : projectData?.project_request?.request_type == 3 &&
                          item?.project_detail_id?.qty
                        ? item?.project_detail_id?.qty
                        : projectData?.project_request?.no_of_packs
                        ? projectData?.project_request?.no_of_packs
                        : ""}
                    </td>
                    <td className="text-start mb-3 px-3">
                      {" "}
                      {item?.project_detail_id?.retail_price &&
                      item?.project_detail_id?.retail_price > 0 &&
                      item?.project_detail_id?.price_per_pack ? (
                        "₹" +
                        commaValue(+item?.project_detail_id?.price_per_pack)
                      ) : item?.project_detail_id?.retail_price > 0 &&
                        item?.project_detail_id?.price_per_pack == 0 ? (
                        <span className="mx-4">-</span>
                      ) : projectData?.project_request?.request_type == 3 &&
                        item?.project_detail_id?.qty ? (
                        commaValue(+request_type)
                      ) : projectData?.project_request?.request_type !== 3 &&
                        projectData?.project_request?.no_of_packs ? (
                        commaValue(+request_type)
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="text-start mb-3 px-3">
                      {" "}
                      {/* {item?.project_detail_id?.retail_price &&
                    item?.project_detail_id?.retail_price > 0
                      ? "₹" +
                        commaValue(Number(sub_total_return).toFixed(2))
                      : ""} */}
                      {item?.project_detail_id?.retail_price &&
                      item?.project_detail_id?.retail_price > 0 &&
                      dis_without_percen ? (
                        dis_without_percen
                      ) : item?.project_detail_id?.retail_price > 0 &&
                        dis_without_percen == "" ? (
                        <span className="mx-4">-</span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="text-center mb-3 px-3">
                      {/* {item?.project_detail_id?.discount_amount &&
                      item?.project_detail_id?.discount_amount > 0
                        ? "₹" +
                          commaValue(
                            Number(
                              +sub_total_return -
                                +item?.project_detail_id?.discount_amount
                            ).toFixed(2)
                          )
                        : ""} */}
                      {item?.project_detail_id?.retail_price &&
                      item?.project_detail_id?.retail_price > 0 &&
                      item?.project_detail_id?.after_discount > 0 ? (
                        "₹" + item?.project_detail_id?.after_discount
                      ) : item?.project_detail_id?.retail_price > 0 &&
                        item?.project_detail_id?.after_discount == 0 ? (
                        <span className="mx-4">-</span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="text-start mb-3 px-3">
                      {" "}
                      {item?.project_detail_id?.retail_price &&
                      item?.project_detail_id?.retail_price > 0
                        ? "₹" +
                          commaValue(Number(before_without_price).toFixed(2))
                        : projectData?.project_request?.request_type == 3 &&
                          item?.project_detail_id?.qty
                        ? commaValue(+request_type_sum)
                        : projectData?.project_request?.request_type !== 3 &&
                          projectData?.project_request?.no_of_packs
                        ? commaValue(+request_type_sum)
                        : ""}
                    </td>
                    <td className="text-start mb-3 px-3">
                      {item?.project_detail_id?.retail_price > 0 &&
                      item?.project_detail_id?.hsn_code ? (
                        item?.project_detail_id?.hsn_code
                      ) : item?.project_detail_id?.retail_price > 0 &&
                        item?.project_detail_id?.hsn_code == null ? (
                        <span className="mx-4">-</span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="text-start mb-3 px-3">
                      {item?.project_detail_id?.tax_percent &&
                      item?.project_detail_id?.retail_price > 0 ? (
                        item?.project_detail_id?.tax_percent +
                        item?.project_detail_id?.tax1_percent +
                        "%"
                      ) : item?.project_detail_id?.retail_price > 0 &&
                        item?.project_detail_id?.tax_percent == 0 ? (
                        <span className="mx-4">-</span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="text-start mb-3 px-3">
                      {item?.project_detail_id?.retail_price > 0 &&
                      item?.project_detail_id?.tax_amount ? (
                        "₹" + commaValue(item?.project_detail_id?.tax_amount)
                      ) : item?.project_detail_id?.retail_price > 0 &&
                        item?.project_detail_id?.tax_amount == 0 ? (
                        <span className="mx-4">-</span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="text-start mb-3 px-3">
                      {" "}
                      {item?.project_detail_id?.final_amount &&
                      item?.project_detail_id?.retail_price > 0 ? (
                        "₹" + commaValue(item?.project_detail_id?.final_amount)
                      ) : item?.project_detail_id?.retail_price > 0 &&
                        item?.project_detail_id?.final_amount == 0 ? (
                        <span className="mx-4">-</span>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default TableComp2;
