import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ConfirmPopup = ({ confirmToggle, confirmEvent }) => {
  return (
    <div className="delete-popup">
      <div className="w-90 w-md-40">
        {" "}
        <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
          Are You Sure Want to Change ?
          <span>
            <button onClick={() => confirmToggle()} className="cust-btn">
              <HighlightOffIcon />
            </button>
          </span>
        </p>
        <div className="d-flex ac-jc gap-5 mt-5 ">
          <button
            onClick={() => confirmToggle(confirmEvent, "close")}
            // className="btn btn-warning w-30"
            className="cust-btn addbtn2  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            No
          </button>
          <button
            onClick={() => {
              confirmToggle(confirmEvent, "action");
            }}
            className="cust-btn addbtn2  ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 py-2 py-md-2 px-5 rounded"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
