import React, { useEffect, useRef, useState } from "react";
import {
  closeIcon,
  head,
  logos,
  product,
  profilePic,
  tshirt1,
} from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
  useLazyEstimateDetailQuery,
  useLazyProjectUserAddressQuery,
} from "../redux/api/api";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ImageGallery from "react-image-gallery";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";
import { Col, Row } from "react-bootstrap";

const EstimationPageA = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [serachParams, setSearchParams] = useSearchParams();
  const [presentionlist, setPresentationList] = useState([]);
  const [billing, setBilling] = useState({});
  const [artwork, setArtwork] = useState([]);
  const [artworkdetails, setArtworkdetails] = useState([]);
  const [estimateViewApi] = useLazyEstimateDetailQuery();
  const [projectUserAddressListNoPageListApi] =
    useLazyProjectUserAddressQuery();
  const [userPopup, setUserPopup] = useState(false);
  const [image, setImages] = useState([]);
  const [gstFinal, setGstFinal] = useState("");
  const [gstTax, setGstTax] = useState("");
  const [discount, setDiscount] = useState("");
  const [totalFinal, setTotalFinal] = useState("");
  const [projectData, setProjectData] = useState({});
  const [discount_percent, setdiscount_percent] = useState("");

  const [tax_percentGst, settax_percentGst] = useState("");
  const [tax_percentGst1, settax_percentGst1] = useState("");
  const [mainShipAddress, setMainShipAddress] = useState({});
  const [gstType, setGSTType] = useState(0);
  const [discountType, setDiscountType] = useState(0);
  const [discount_overall, setSubtotal_overall] = useState("");
  const [subtotal, setSubtotal] = useState("");

  const [gstTypes, setGSTTypes] = useState(0);
  const handleUserPopup = () => {
    setUserPopup(!userPopup);
  };

  const getView = () => {
    dispatch(saveLoader(true));
    estimateViewApi(
      location?.state?.data?.id || serachParams?.get("project_id")
    )
      .unwrap()
      .then((res) => {
        if (res?.status == "success" || res?.data?.status == "success") {
          const projectData_ = res?.project || {};
          const { billing_address, project, shipping_address } = res;
          setBilling({
            ...billing_address,
            project,
            shipping_address: shipping_address,
          });
          setProjectData(res);
          let project_details_with_sizes = [];

          res?.project_details_with_sizes?.map((item, ind) => {
            project_details_with_sizes.push(item);
          });

          let projectPresentationOrder = [];

          res?.project?.projectPresentationOrder?.map((item, ind) => {
            let obj = {
              ...item,
            };

            let finder = project_details_with_sizes?.find(
              (i) => i?.detail?.id == item?.project_detail_id?.id
            );

            if (finder?.detail?.id) {
              obj.project_detail = finder;
            } else {
              obj.project_detail = null;
            }

            projectPresentationOrder.push(obj);
          });

          projectPresentationOrder.sort((a, b) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });

          setPresentationList(projectPresentationOrder);
          extra_charges_cus(projectPresentationOrder);
          if (projectData_?.tax_type == 2) {
            if (projectData_?.tax_percent) {
              if (projectData_?.tax1_percent) {
                settax_percentGst1(projectData_?.tax1_percent);
              }
              settax_percentGst(projectData_?.tax_percent);
              setGstTax(projectData_?.tax_percent);
              setDiscount(projectData_?.discount_amount);
              setdiscount_percent(projectData_?.discount_percent);
            } else {
              // getTax(data);
            }
            let temp = projectData_?.projectDetail?.reduce(
              (n, { retail_price }) => n + retail_price,
              0
            );

            setTotalFinal(projectData_?.total_amount);
            setSubtotal(Number(temp).toFixed(2));
            setGstFinal(projectData_?.tax_total_amount);
          } else if (projectData_?.tax_type == 1) {
            let temp = projectData_?.projectDetail?.reduce(
              (n, { retail_price }) => n + retail_price,
              0
            );
            setSubtotal(Number(temp).toFixed(2));
            setGstTax(projectData_?.tax_percent + projectData_?.tax1_percent);
            setGstFinal(projectData_?.tax_total_amount);
            setTotalFinal(projectData_?.total_amount);
            setDiscount(projectData_?.discount_amount);
            setdiscount_percent(projectData_?.discount_percent);
          }
          if (projectData_?.discount_type) {
            setDiscountType(projectData_?.discount_type);
          }
          let discountAmount = projectData_?.projectDetail?.reduce(
            (n, { discount_amount }) => n + +discount_amount,
            0
          );
          if (discountAmount) {
            let discountAmount_dis = Number(discountAmount).toFixed(2);
            setSubtotal_overall(+discountAmount_dis);
          }

          const projectDetails = res?.project_details_with_sizes || [];
          const projectdetail = res?.project?.projectDetail;
          const allArtworks = projectdetail?.map(
            (detail) => detail.artworks || []
          );
          const detailsArray = projectDetails.map((item) => item.detail);
          setArtwork(detailsArray);
          const art = detailsArray.map((item) => item.project_artworks);
          setArtworkdetails(art);
          const imagess = detailsArray.map((item) => item.product);
          setImages(imagess);
        }
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("Error fetching project details:", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id || serachParams?.get("project_id")) {
      getView();
    }
  }, []);
  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  const extra_charges_cus = (project_detail) => {
    if (project_detail?.length > 0) {
      let extra_charges_arr = [];
      project_detail?.map((item, ind) => {
        let temp = [];
        let include_price = item?.project_detail_id?.extra_charges?.filter(
          (i) => +i?.client_price == 1
        );
        let display_to_client = item?.project_detail_id?.extra_charges?.filter(
          (i) => +i?.client_price == 2
        );

        let extra_obj = {};
        include_price?.forEach((price) => {
          if (extra_obj[price.client_price]) {
            extra_obj[price.client_price].final_amount += +price.final_amount;
            extra_obj[price.client_price].net_price += +price.net_price;
            extra_obj[price.client_price].margin += +price.margin;
          } else {
            extra_obj[price.client_price] = {
              name: price?.name,
              client_price: price?.client_price,
              margin: +price?.margin,
              net_price: +price?.net_price,
              final_amount: +price?.final_amount,
            };
          }
        });

        let discount_amount =
          +item?.project_detail_id?.discount_type == 1
            ? +item?.project_detail_id?.discount_amount
            : 0;

        // FOR INCLUDE PRICE
        if (Object.values(extra_obj)?.length > 0) {
          Object.values(extra_obj).map((price, Pind) => {
            let discount_percent = 0;

            if (item?.project_detail_id?.discount_percent) {
              discount_percent =
                (+item?.project_detail_id?.net_price *
                  item?.project_detail_id?.discount_percent) /
                100;
            }

            let discount_value = +item?.project_detail_id?.net_price;
            // let discount_value = +item?.project_detail_id?.net_price - discount_percent;

            // let net_amount = +discount_value - discount_amount;
            let net_amount = +discount_value;

            // let final = +net_amount + +price?.final_amount;
            let final = +net_amount;
            let net_price = +net_amount + +price?.net_price;
            let net_price_;

            let tax =
              item?.project_detail_id?.tax_percent +
              item?.project_detail_id?.tax1_percent;
            let tax_value = (+final * tax) / 100;
            let tax_amount = +final + +tax_value;
            let obj = { ...price };
            obj.margin = +price?.margin;

            if (item?.project_detail_id?.after_discount > 0) {
              net_price_ = item?.project_detail_id?.after_discount;
              // obj.net_price = net_price_ + price?.net_price;
              obj.net_price = net_price_;
              obj.price_per_pack = item?.project_detail_id?.price_per_pack;
            } else {
              net_price_ = item?.project_detail_id?.price_per_pack;
              obj.price_per_pack = +item?.project_detail_id?.price_per_pack;
              obj.net_price = "";
            }

            // obj.final_amount = +discount_value + +price?.final_amount;
            obj.final_amount = +discount_value;
            obj.discount_amount = +discount_amount;
            obj.tax = tax;
            obj.tax_value = +Number(tax_value).toFixed(2);
            obj.tax_amount = +Number(tax_amount).toFixed(2);

            if (item?.project_detail_id?.after_discount) {
              let discountPercentage =
                ((+item?.project_detail_id?.price_per_pack -
                  +item?.project_detail_id?.after_discount) /
                  +item?.project_detail_id?.price_per_pack) *
                100;
              let discountPercentage_ = Number(discountPercentage).toFixed(2);
              obj.dis_percen = +discountPercentage_;
            }

            temp.push(obj);
          });
        } else if (
          item?.project_detail_id?.extra_charges?.length > 0 &&
          Object.values(extra_obj).length == 0
        ) {
          let discount_percent = 0;

          if (item?.project_detail_id?.discount_percent) {
            discount_percent =
              (+item?.project_detail_id?.net_price *
                item?.project_detail_id?.discount_percent) /
              100;
          }

          let discount_value =
            +item?.project_detail_id?.net_price - discount_percent;

          // let net_amount = +discount_value;
          let net_amount = +item?.project_detail_id?.net_price;

          // let final = +net_amount - discount_amount;
          let final = +net_amount;
          let tax =
            item?.project_detail_id?.tax_percent +
            item?.project_detail_id?.tax1_percent;
          let tax_value = (+final * tax) / 100;
          let tax_amount = +final + +tax_value;
          let obj = {};
          obj.margin = +item?.project_detail_id?.margin_percent;
          // obj.net_price = +item?.project_detail_id?.net_price;
          obj.final_amount = +net_amount;
          obj.discount_amount = +discount_amount;
          obj.tax = tax;
          obj.tax_value = +Number(tax_value).toFixed(2);
          obj.tax_amount = +Number(tax_amount).toFixed(2);

          if (item?.project_detail_id?.after_discount > 0) {
            obj.price_per_pack = item?.project_detail_id?.price_per_pack;
            obj.net_price = +item?.project_detail_id?.price_per_pack;
          } else {
            obj.price_per_pack = item?.project_detail_id?.price_per_pack;
            obj.net_price = "";
          }

          if (item?.project_detail_id?.after_discount) {
            let discountPercentage =
              ((+item?.project_detail_id?.price_per_pack -
                +item?.project_detail_id?.after_discount) /
                +item?.project_detail_id?.price_per_pack) *
              100;
            let discountPercentage_ = Number(discountPercentage).toFixed(2);
            obj.dis_percen = +discountPercentage_;
          }

          temp.push(obj);
        }

        // FOR DISPLAY TO CLIENT
        display_to_client?.forEach((price) => {
          let tax =
            item?.project_detail_id?.tax_percent +
            item?.project_detail_id?.tax1_percent;
          let tax_value = (+price?.final_amount * tax) / 100;
          let tax_amount = +price?.final_amount + +tax_value;
          let obj = { ...price };
          obj.margin = +price?.margin;
          obj.final_amount = +price?.final_amount;
          obj.tax = tax;
          obj.tax_value = +Number(tax_value).toFixed(2);
          obj.tax_amount = +Number(tax_amount).toFixed(2);

          if (item?.project_detail_id?.after_discount > 0) {
            obj.net_price = +price?.net_price;
          } else {
            obj.price_per_pack = +price?.net_price;
            obj.net_price = "";
          }

          temp.push(obj);
        });

        let prject_img_url = "";

        if (item?.project_detail_id?.product_sku?.color_id) {
          let finder = item?.project_detail_id?.product?.colorImages?.find(
            (i) => i?.color_id == item?.project_detail_id?.product_sku?.color_id
          );
          prject_img_url = finder?.image_url;
        } else {
          prject_img_url = item?.project_detail_id?.product?.defaultImage;
        }

        let extra_charges_obj = {
          ...item,
          prject_img_url: prject_img_url,
        };

        let project_detail_id = {
          ...item?.project_detail_id,
        };

        if (temp?.length > 0) {
          project_detail_id.extra_charges = temp;
        } else {
          project_detail_id.extra_charges = temp;

          if (+item?.project_detail_id?.discount_type == 1) {
            let discount_amount_ = item?.project_detail_id?.discount_amount
              ? +item?.project_detail_id?.discount_amount
              : 0;

            let net_price_ = +item?.project_detail_id?.net_price;

            let tax_ =
              +item?.project_detail_id?.tax1_percent +
              +item?.project_detail_id?.tax_percent;

            let minus_price_ = net_price_ - discount_amount_;

            let tax_value_ = (minus_price_ * tax_) / 100;

            let tax_price_ = tax_value_ + minus_price_;

            // project_detail_id.net_amount = minus_price_;
            // project_detail_id.tax_amount = tax_value_;
            // project_detail_id.final_amount = tax_price_
          }
        }

        extra_charges_obj.project_detail_id = project_detail_id;

        extra_charges_arr.push(extra_charges_obj);
      });

      let filters = [];

      extra_charges_arr?.map((item, ind) => {
        if (item?.project_detail_id?.status == 1) {
          filters.push(item);
        }

        if (item?.project_detail_id?.status == 2) {
          filters.push(item);
        }

        if (item?.title) {
          let obj = {};
          obj.id = item?.id;
          obj.order = item?.order;
          obj.prject_img_url = null;
          obj.project_detail_id = null;
          obj.project_id = item?.project_id;
          obj.title = item?.title;
          filters.push(obj);
        }
      });

      filters.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });

      setPresentationList(filters);
    }
  };

  const totalSum = (sub_total, project_data) => {
    let amount = sub_total;

    if (project_data?.project?.shipping_storage_amount) {
      amount += +project_data?.project?.shipping_storage_amount;
    }

    if (project_data?.project?.discount_type == 1) {
      let discountAmount = project_data?.project?.projectDetail?.reduce(
        (n, { discount_amount }) => n + +discount_amount,
        0
      );

      let discountAmount_dis = Number(discountAmount).toFixed(2);
      // amount = +amount - +discountAmount_dis;
    }

    if (project_data?.project?.discount_type == 2) {
      amount = +amount - +project_data?.project?.discount_amount;
    }

    project_data?.project?.project_fixed_charge?.map((fixedItem, fixedInd) => {
      let fixedItem_amount = +fixedItem?.amount - +fixedItem?.tax_amount;
      amount += +fixedItem_amount;
    });

    let price = Number(amount).toFixed(2);

    return price.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
  };

  const totalGstSum = (sub_total, project_data) => {
    let amount = sub_total;

    if (project_data?.project?.shipping_storage_tax >= 0) {
      amount += +project_data?.project?.shipping_storage_tax;
    }

    project_data?.project?.project_fixed_charge?.map((fixedItem, fixedInd) => {
      amount += +fixedItem?.tax_amount;
    });

    let price = Number(amount).toFixed(2);

    return price.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  const sharePaymentLink = async () => {
    if (projectData?.project?.id) {
      let userDetails = {
        project_id: projectData?.project?.id,
        total_amount: projectData?.project?.total_amount,
        user_id: projectData?.project?.project_request?.user?.id,
        name: projectData?.project?.project_request?.user?.first_name,
        email: projectData?.project?.project_request?.user?.email,
        contact: projectData?.project?.project_request?.user?.mobile,
      };
      let user = JSON.stringify(userDetails);
      // Encode the String
      let encodedStringBtoA = btoa(user);
      let url_encode = `https://dev.merchhq.io/#/method?_p=${encodedStringBtoA}`;
      // let url_encode = `http://localhost:3001/#/method?_p=${encodedStringBtoA}`;
      try {
        await navigator.clipboard.writeText(url_encode);
        toast.success("Url Copied to clibboard");
        /* Resolved - text copied to clipboard successfully */
      } catch (err) {
        console.error("Failed to copy: ", err);
        /* Rejected - text failed to copy to the clipboard */
      }
    }
  };

  // console.log("projectData", projectData);

  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      {userPopup && (
        <div className="modal-popup">
          <div className="back-close" />
          <div
            style={{ width: "60%" }}
            className="center-content d-flex flex-column flex-lg-row p-0 m-0"
          >
            <button
              onClick={() => handleUserPopup()}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="" />
            </button>
            <div className="d-flex flex-column as-jc px-3 mx-auto px-3 px-lg-5 w-100 h-100 mb-5">
              <div className="w-100 d-flex ac-jc flex-column flex-md-row">
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-lg-2">
                    <label className="w-100 position-relative">
                      <div className="rej-img">
                        <img src={profilePic} alt="No Image" />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                      Location
                    </p>
                    <input
                      type="text"
                      placeholder="Specify the placement of your artwork"
                      className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                    />
                  </div>
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                      Dimensions
                    </p>
                    <input
                      type="text"
                      placeholder="W 2.3 X H 4.5"
                      className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                    />
                  </div>
                </div>
              </div>
              <div className="ac-jb mt-3 mt-lg-4 pop-input w-100">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-2">
                  Additional Notes
                </p>
                <input
                  type="text"
                  placeholder="Notes"
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                />
              </div>
              <button
                className="get-start-btn border-0 mt-4 mx-auto d-flex py-2"
                onClick={() => handleUserPopup()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2  banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className=""></div>

            <div className="ps-2 ms-2 primary">
              <img
                alt="img"
                src={logos}
                style={{ width: "150px", objectFit: "cover" }}
              />
              {/* <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                MerchHQ Pvt.Ltd.
              </h2> */}
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                ESTIMATION #{billing?.project?.estimation_code} for{" "}
                {billing?.project?.project_request?.user?.first_name}
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project {billing?.project?.project_code}
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div
          className="d-flex flex-wrap    w-100  mt-4  me-3 p-4"
          style={{
            backgroundColor: "#e9f0ff",
            borderRadius: "20px",
            lineHeight: "35px",
            flexWrap: "wrap",
          }}
        >
          <div className="col-md-3 mt-sm-2 p-2">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                SHIPPPING ADDRESS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-80">
                {billing?.shipping_address?.address1}
              </p>
            </div>
          </div>
          <div className="col-md-3 mt-sm-2 p-2">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                BILLING ADDRESS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-80">
                {billing?.address1}
              </p>
            </div>
          </div>

          <div
            className="col-md-2 ps-4 mt-sm-2 p-2 left-line"
            style={{
              borderLeft: "1px",
              borderStyle: "solid",
              borderColor: "#cacaca",
              borderRight: "0",
              borderBottom: "0",
              borderTop: "0",
            }}
          >
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                PROJECT #
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                {billing?.project?.project_code}
              </p>
            </div>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TERMS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                {billing?.project?.terms_condition}
              </p>
            </div>
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
              ESTIMATE #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {billing?.project?.estimation_code}
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CUSTOMER PO #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {billing?.project?.customer_po}
            </p>
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
              IN HAND DATE
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {billing?.project?.in_hand_date}
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CURRENCY
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              INR
            </p>
          </div>
        </div>
        {presentionlist?.map((item, index) => {
          // if (item?.status === 2 || item?.status === 1) {
          // if (item?.pricing_status === 1) {
          // console.log(billing?.project?.projectTags, "projectdetail");
          const size = item?.project_detail?.product_skus_with_matched_sizes;
          const match = size?.map((item) => item?.matched_sizes);
          const detail = item?.project_detail?.detail?.project_artworks;
          let pricePerPack =
            +item?.project_detail_id?.net_price / +item?.project_detail_id?.qty;
          // let sub_total_return = item?.project_detail_id?.discount_amount
          //   ? item?.project_detail_id?.net_price -
          //     item?.project_detail_id?.discount_amount
          //   : item?.project_detail_id?.net_price;

          let sub_total_return = item?.project_detail_id?.net_price;

          if (item?.project_detail_id?.extra_charges?.length > 0) {
            item?.project_detail_id?.extra_charges?.map((items, inds) => {
              if (+items?.client_price == 1) {
                sub_total_return += +items?.final_amount;
              } else if (+items?.client_price == 2) {
                sub_total_return += +items?.final_amount;
              }
            });
          }

          let image_url_ = [];

          if (item?.project_detail?.image_urls?.length > 0) {
            item?.project_detail?.image_urls?.map((item, ind) => {
              image_url_.push(item);
            });
          } else {
            image_url_.push(
              item?.project_detail?.detail?.product?.default_image_url
            );
          }

          // FOR WITHOUT EXTRA CHARGE
          let dis_without_percen = "";
          let before_without_price = item?.project_detail_id?.retail_price;
          if (item?.project_detail_id?.after_discount > 0) {
            let discountPercentage =
              ((+item?.project_detail_id?.price_per_pack -
                +item?.project_detail_id?.after_discount) /
                +item?.project_detail_id?.price_per_pack) *
              100;
            dis_without_percen = Number(discountPercentage).toFixed(2) + "%";
          }

          // console.log("item", item);

          return (
            <div>
              {item?.title ? (
                <div className=" d-flex mt-3">
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 primary px-3 py-2 bg-primary2">
                    {item?.title}
                  </p>
                </div>
              ) : (
                <div
                  className="   w-100  mt-4  me-3 p-4"
                  style={{
                    border: "1px solid #839dd1",
                    // backgroundColor: "#ecfaff",
                    borderRadius: "20px",
                    lineHeight: "35px",
                    flexWrap: "wrap",
                    overflow: "scroll",
                  }}
                >
                  <div className="d-flex flex-wrap  align-items-start">
                    <div
                      className="col-md-5 mt-4 rounded "
                      style={{
                        backgroundColor: "#e9f0ff",
                        borderRadius: "32px",
                        padding: "12px",
                        textAlign: "center",
                      }}
                    >
                      {/* {item?.image_urls > 0 && ( */}

                      <ImageGallery
                        key={index}
                        items={(image_url_ || []).map((img) => ({
                          // items={item?.image_url.map((img) => ({
                          original: img,
                          thumbnail: img,
                        }))}
                      />
                      {/* )} */}
                      {/* )})} */}
                    </div>
                    <div className="col-md-7 p-4 ">
                      <div className="w-100  ">
                        <div style={{}}>
                          <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 primary">
                            {item?.project_detail?.detail?.product?.name}
                          </h2>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                            {item?.project_detail?.detail?.product?.description}
                          </p>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="d-flex flex-wrap ">
                          <div className="col-md-4">
                            <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
                              Colors:
                            </p>
                            <div className="d-flex">
                              <div className="lt-Blue">
                                <FiberManualRecordIcon
                                  style={{
                                    color:
                                      item?.project_detail?.color?.hex_code,
                                  }}
                                />
                              </div>
                              <p>{item?.project_detail?.color?.name}</p>
                            </div>
                          </div>
                        </div>
                        {/* {tab == 1 && ( */}

                        {/* )} */}
                        {/* <div className="w-100">
                <textarea
                  className="editBtn bg-primary2 rounded border-0 w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  style={{ height: "100px" }}
                  placeholder="Comment on this item..."
                ></textarea>
              </div>
              <div className="d-flex ac-jb">
                <button className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4">
                  Add Comment <PlaylistAddCheckIcon />
                </button>

              </div> */}
                      </div>
                      <div className="mt-3">
                        <div className="d-flex ">
                          <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 primary col-md-2">
                            Size:
                          </p>
                          <div className="d-flex">
                            {item?.project_detail?.size_details_with_variants?.map(
                              (sizeVar, sizeInd) => {
                                return (
                                  <p
                                    className="primary f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 px-3"
                                    key={sizeInd}
                                  >
                                    {sizeVar?.size_variant
                                      ? sizeVar?.size_variant
                                      : "N/A"}
                                  </p>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="d-flex ">
                          <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 primary col-md-2">
                            Qty:
                          </p>
                          <div className="d-flex">
                            {item?.project_detail?.size_details_with_variants?.map(
                              (sizeVar, sizeInd) => {
                                return (
                                  <p
                                    className=" f2 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 px-3"
                                    key={sizeInd}
                                  >
                                    {sizeVar?.size_detail?.qty}
                                  </p>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-100  my-4 overflow-scroll bg-lt-blue">
                      <table className="w-100 tabblle">
                        <thead>
                          <tr className="">
                            <th className="f3 text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  text-nowrap primary ">
                              ITEM
                            </th>
                            <th className="f3 text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  text-nowrap mx-4 primary">
                              QTY
                            </th>
                            <th className="text-center f3  fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  text-nowrap primary mx-4 px-2">
                              PRICE PER KIT
                            </th>
                            {/* <th className="text-center f3  fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  text-nowrap primary px-4">
                                  DISCOUNT
                                </th> */}
                            {item?.project_detail_id?.discount_amount > 0 && (
                              <th className="text-center f3  fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  text-nowrap primary px-2">
                                PRICE AFTER DISCOUNT
                              </th>
                            )}
                            <th className=" text-center f3  fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  text-nowrap primary px-2">
                              TOTAL BEFORE TAX
                            </th>
                            <th className=" text-center f3  fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  text-nowrap primary px-2">
                              HSN
                            </th>
                            <th className="f3 text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  text-nowrap primary px-2">
                              TAX
                            </th>
                            <th className=" f3 text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  text-nowrap primary mx-4">
                              TAX VALUE
                            </th>
                            <th className="f3 text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  text-nowrap primary mx-4">
                              TOTAL AFTER TAX
                            </th>
                          </tr>
                        </thead>

                        <tbody style={{ lineHeight: "30px" }}>
                          {item?.project_detail_id?.extra_charges?.length >
                          0 ? (
                            item?.project_detail_id?.extra_charges?.map(
                              (charge, eInd) => {
                                return (
                                  <tr className="rowspan" key={eInd}>
                                    <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                      {+charge?.client_price == 2
                                        ? charge?.name
                                        : item?.project_detail_id?.product_sku
                                            ?.variant_sku}
                                    </td>

                                    <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                      {item?.project_detail_id?.qty ? (
                                        item?.project_detail_id?.qty
                                      ) : (
                                        <span className="mx-4">-</span>
                                      )}
                                    </td>
                                    <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 px-2">
                                      {" "}
                                      {/* {charge?.net_price
                                          ? "₹" +
                                            commaValue(
                                              Number(charge?.net_price).toFixed(2)
                                            )
                                          : ""} */}
                                      {charge?.price_per_pack ? (
                                        "₹" +
                                        commaValue(
                                          Number(
                                            charge?.price_per_pack
                                          ).toFixed(2)
                                        )
                                      ) : (
                                        <span className="mx-4">-</span>
                                      )}
                                    </td>
                                    {/* <td className="text-center mb-3 px-3">
                                          {charge?.final_amount
                                          ? "₹" +
                                            commaValue(
                                              Number(charge?.final_amount).toFixed(2)
                                            )
                                          : ""}
                                          {charge?.dis_percen ? (
                                            charge?.dis_percen + "%"
                                          ) : (
                                            <span className="mx-4">-</span>
                                          )}
                                        </td> */}

                                    {item?.project_detail_id?.discount_amount >
                                      0 && (
                                      <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                        {charge?.net_price ? (
                                          "₹" +
                                          commaValue(
                                            Number(charge?.net_price).toFixed(2)
                                          )
                                        ) : (
                                          <span className="mx-4 text-center">
                                            -
                                          </span>
                                        )}
                                      </td>
                                    )}
                                    <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 px-2">
                                      {charge?.final_amount ? (
                                        "₹" +
                                        commaValue(
                                          Number(charge?.final_amount).toFixed(
                                            2
                                          )
                                        )
                                      ) : (
                                        <span className="mx-4 text-center">
                                          -
                                        </span>
                                      )}
                                    </td>

                                    <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 px-2">
                                      {item?.project_detail_id?.hsn_code ? (
                                        item?.project_detail_id?.hsn_code
                                      ) : (
                                        <span className="mx-4 text-center">
                                          -
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 px-2">
                                      {charge?.tax ? (
                                        charge?.tax + "%"
                                      ) : (
                                        <span className="mx-4 text-center">
                                          -
                                        </span>
                                      )}
                                    </td>

                                    <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                      {charge?.tax_value ? (
                                        "₹" +
                                        commaValue(
                                          Number(charge?.tax_value).toFixed(2)
                                        )
                                      ) : (
                                        <span className="mx-4 text-center">
                                          -
                                        </span>
                                      )}
                                    </td>
                                    {charge?.tax_amount ? (
                                      <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                        {"₹" +
                                          commaValue(
                                            Number(charge?.tax_amount).toFixed(
                                              2
                                            )
                                          )}
                                      </td>
                                    ) : (
                                      <span className="mx-4 text-center">
                                        -
                                      </span>
                                    )}
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr className="rowspan">
                              <td>
                                {item?.project_detail_id?.product_sku
                                  ?.variant_sku &&
                                item?.project_detail_id?.retail_price > 0
                                  ? item?.project_detail_id?.product_sku
                                      ?.variant_sku
                                  : ""}
                              </td>
                              <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                {item?.project_detail_id?.qty &&
                                item?.project_detail_id?.retail_price > 0
                                  ? item?.project_detail_id?.qty
                                  : ""}
                              </td>
                              <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                {" "}
                                {item?.project_detail_id?.retail_price &&
                                item?.project_detail_id?.retail_price > 0 &&
                                item?.project_detail_id?.price_per_pack ? (
                                  "₹" +
                                  commaValue(
                                    +item?.project_detail_id?.price_per_pack
                                  )
                                ) : item?.project_detail_id?.retail_price > 0 &&
                                  item?.project_detail_id?.price_per_pack ==
                                    0 ? (
                                  <span className="mx-4">-</span>
                                ) : (
                                  ""
                                )}
                              </td>
                              {/* <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                {" "}
                                {/* {item?.project_detail_id?.retail_price &&
                                  item?.project_detail_id?.retail_price > 0
                                    ? "₹" +
                                      commaValue(Number(sub_total_return).toFixed(2))
                                    : ""} 
                                {item?.project_detail_id?.retail_price &&
                                item?.project_detail_id?.retail_price > 0 &&
                                dis_without_percen ? (
                                  dis_without_percen
                                ) : item?.project_detail_id?.retail_price > 0 &&
                                  dis_without_percen == "" ? (
                                  <span className="mx-4">-</span>
                                ) : (
                                  ""
                                )}
                              </td> */}
                              {item?.project_detail_id?.discount_amount > 0 && (
                                <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                  {/* {item?.project_detail_id?.discount_amount &&
                                    item?.project_detail_id?.discount_amount > 0
                                      ? "₹" +
                                        commaValue(
                                          Number(
                                            +sub_total_return -
                                              +item?.project_detail_id?.discount_amount
                                          ).toFixed(2)
                                        )
                                      : ""} */}
                                  {item?.project_detail_id?.retail_price &&
                                  item?.project_detail_id?.retail_price > 0 &&
                                  item?.project_detail_id?.after_discount >
                                    0 ? (
                                    "₹" +
                                    item?.project_detail_id?.after_discount
                                  ) : item?.project_detail_id?.retail_price >
                                      0 &&
                                    item?.project_detail_id?.after_discount ==
                                      0 ? (
                                    <span className="mx-4">-</span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              )}
                              <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                {" "}
                                {item?.project_detail_id?.retail_price &&
                                item?.project_detail_id?.retail_price > 0
                                  ? "₹" +
                                    commaValue(
                                      Number(before_without_price).toFixed(2)
                                    )
                                  : ""}
                              </td>
                              <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                {item?.project_detail_id?.retail_price > 0 &&
                                item?.project_detail_id?.hsn_code ? (
                                  item?.project_detail_id?.hsn_code
                                ) : item?.project_detail_id?.retail_price > 0 &&
                                  item?.project_detail_id?.hsn_code == null ? (
                                  <span className="mx-4">-</span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                {item?.project_detail_id?.tax_percent &&
                                item?.project_detail_id?.retail_price > 0 ? (
                                  item?.project_detail_id?.tax_percent +
                                  item?.project_detail_id?.tax1_percent +
                                  "%"
                                ) : item?.project_detail_id?.retail_price > 0 &&
                                  item?.project_detail_id?.tax_percent == 0 ? (
                                  <span className="mx-4">-</span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                {item?.project_detail_id?.retail_price > 0 &&
                                item?.project_detail_id?.tax_amount ? (
                                  "₹" +
                                  commaValue(
                                    item?.project_detail_id?.tax_amount
                                  )
                                ) : item?.project_detail_id?.retail_price > 0 &&
                                  item?.project_detail_id?.tax_amount == 0 ? (
                                  <span className="mx-4">-</span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td className="text-center fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14  mb-3 ">
                                {" "}
                                {item?.project_detail_id?.final_amount &&
                                item?.project_detail_id?.retail_price > 0 ? (
                                  "₹" +
                                  commaValue(
                                    item?.project_detail_id?.final_amount
                                  )
                                ) : item?.project_detail_id?.retail_price > 0 &&
                                  item?.project_detail_id?.final_amount == 0 ? (
                                  <span className="mx-4">-</span>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {detail?.map((item, index) => {
                      // console.log(item,"artworkdetails")
                      return (
                        <div className="col-md-5">
                          <div className="d-flex flex-wrap w-100 ac-jb mb-4">
                            {/* <div className="bg-primary2  rounded p-3 mt-3 w-50"> */}
                            {/* <p className="f3 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 fs-xl-13 fs-xxl-14 primary">
                  TYPE : 1
                </p> */}
                            {/* </div> */}

                            <div>
                              {/* {item && item.map((artwork, artworkIndex) => ( */}
                              <table
                                className="ms-3 mt-2 w-100"
                                style={{ lineHeight: "30px" }}
                              >
                                <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary">
                                  ARTWORK DETAILS
                                </p>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN NAME
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.logo_name
                                        ? item?.logo_name
                                        : "N/A"}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      IMPRINT TYPE
                                    </p>
                                  </td>

                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.decorative_method?.name}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN LOCATION
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.uom === "1"
                                        ? "Inches"
                                        : item?.uom === "2"
                                        ? "Stitch"
                                        : "N/A"}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN SIZE
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.logo_length}*{item?.logo_breadth}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN COLOR
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.logo_color}
                                    </p>
                                  </td>
                                </tr>
                              </table>
                              {/* ))} */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div class="col-md-5 ms-auto ">
        <div className="d-flex ac-jc w-100 ms-5 mt-2">
          <div className="w-100">
            {discountType == 1 ? (
              <p className="mt-3 mb-2">Discount Amount</p>
            ) : (
              <p>&nbsp;</p>
            )}
          </div>
          <div className="w-100">
            {discountType == 1 ? (
              <p className="mt-3 mb-2">
                {discount_overall ? "₹" + commaValue(discount_overall) : "0"}
              </p>
            ) : (
              <p>&nbsp;</p>
            )}
          </div>
        </div>
        <div className="d-flex ac-jc w-100 ms-5 mt-2">
          <div className="w-100">
            <p>Subtotal</p>
          </div>
          <div className="w-100">
            <p> {subtotal ? "₹" + commaValue(subtotal) : "0"}</p>
          </div>
        </div>
        {projectData?.project?.discount_percent > 0 && (
          <div className="d-flex ac-jc w-100 ms-5 mt-3">
            <div className="w-100">
              <p className="">Discount % {discount_percent}</p>
            </div>
            <div className="w-100">
              <p className="">
                {discount ? "₹" + commaValue(Number(discount).toFixed(2)) : "0"}
              </p>
            </div>
          </div>
        )}
        {projectData?.project?.discount_amount > 0 && (
          <div className="d-flex ac-jc w-100 ms-5 mt-3">
            <div className="w-100">
              <p className="">Overall Subtotal</p>
            </div>
            <div className="w-100">
              <p className="">
                {subtotal
                  ? "₹" + commaValue(Number(+subtotal - +discount).toFixed(2))
                  : "0"}
              </p>
            </div>
          </div>
        )}
        {projectData?.project?.project_fixed_charge?.length > 0 && (
          <div className="d-flex ac-jc w-100 ms-5 mt-3">
            <div className="w-100">
              {projectData?.project?.project_fixed_charge?.map(
                (fixedItem, fixedInd) => {
                  return (
                    <p className="" key={fixedInd}>
                      {fixedItem?.fixed_charge?.name} (
                      {projectData?.project?.tax1_percent +
                        projectData?.project?.tax1_percent}
                      %)
                    </p>
                  );
                }
              )}
            </div>
            <div className="w-100">
              {projectData?.project?.project_fixed_charge?.map(
                (fixedItem, fixedInd) => {
                  return (
                    <p className="" key={fixedInd}>
                      ₹
                      {Number(
                        fixedItem?.amount - fixedItem?.tax_amount
                      ).toFixed(2)}
                    </p>
                  );
                }
              )}
            </div>
          </div>
        )}
        <div className="d-flex ac-jc w-100 ms-5 mt-3">
          <div className="w-100">
            <p>Shipping Amount</p>
          </div>
          <div className="w-100">
            <p className="">
              {projectData?.project?.shipping_storage_amount
                ? "₹" +
                  commaValue(projectData?.project?.shipping_storage_amount)
                : "0"}
            </p>
          </div>
        </div>

        <div className="d-flex ac-jc w-100 ms-5 mt-3">
          <div className="w-100">
            <p>Total</p>
          </div>
          <div className="w-100">
            <p className="">₹{totalSum(+subtotal, projectData)}</p>
          </div>
        </div>

        <div className="d-flex ac-jc w-100 ms-5 mt-3">
          <div className="w-100">
            <p>Products GST</p>
          </div>
          <div className="w-100">
            <p className="">
              {gstFinal ? "₹" + commaValue(Number(+gstFinal).toFixed(2)) : "0"}
            </p>
          </div>
        </div>

        {projectData?.project?.project_fixed_charge?.length > 0 && (
          <div className="d-flex ac-jc w-100 ms-5 mt-3">
            <div className="w-100">
              <p>Fixed Charges GST</p>
            </div>
            <div className="w-100">
              {projectData?.project?.project_fixed_charge?.map(
                (fixedItem, fixedInd) => {
                  return (
                    <p className="" key={fixedInd}>
                      ₹{Number(fixedItem?.tax_amount).toFixed(2)}
                    </p>
                  );
                }
              )}
            </div>
          </div>
        )}

        <div className="d-flex ac-jc w-100 ms-5 mt-3">
          <div className="w-100">
            <p>Shipping GST</p>
          </div>
          <div className="w-100">
            <p className="">
              {projectData?.project?.shipping_storage_tax
                ? `₹${commaValue(
                    Number(+projectData?.project?.shipping_storage_tax).toFixed(
                      2
                    )
                  )}`
                : "0"}
            </p>
          </div>
        </div>
        <div className="d-flex ac-jc w-100 ms-5 mt-3">
          <div className="w-100">
            <p>Total GST</p>
          </div>
          <div className="w-100">
            <p className="">₹{totalGstSum(gstFinal, projectData)}</p>
          </div>
        </div>

        <div className="d-flex ac-jc w-100 ms-5 mt-3">
          <div className="w-100">
            <p>Final Amount</p>
          </div>
          <div className="w-100">
            <p className="">
              {totalFinal ? "₹" + commaValue(totalFinal) : "0"}
            </p>
          </div>
        </div>

        <div className="text-end mt-3">
          {presentionlist?.[0]?.project_detail_id?.estimate_status !== 6 && (
            <button
              className="mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded mx-5"
              onClick={() => sharePaymentLink()}
            >
              Share Payment Link
            </button>
          )}
        </div>
      </div>
      <div className="">
        <div
          className="mt-3  px-2"
          style={{
            backgroundColor: "#07679c",
            padding: "20px",
            lineHeight: "30px",
          }}
        >
          <div className="w-90 m-auto d-flex flex-wrap ac-jb">
            <div className="">
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                Questions About this estimate?
              </p>
              <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
                Roger Daniel
              </h2>
              <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
                roger@companystore.io
              </a>
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                merchHQ Pvt.Ltd.
              </p>
              <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
                #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
                Kartnataka, India.
              </p>
              <button
                class=" d-flex ac-jc ps-3 mt-sm-2"
                style={{
                  backgroundColor: "#0082ca",
                  border: "none",
                  borderRadius: "7px",
                  padding: "5px",
                  color: "#fff",
                }}
              >
                <UnsubscribeIcon />

                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white mb-0">
                  Contact us
                </p>
              </button>
            </div>
            <div className="col-md-4 d-flex ae-je mt-3">
              <div class="d-flex white mt-2  justify-content-around">
                <FacebookRoundedIcon className="me-2" />

                <TwitterIcon className="mx-2" />

                <LinkedInIcon className="mx-2" />

                <InstagramIcon className="mx-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimationPageA;
