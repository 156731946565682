import React, { useEffect, useState } from "react";
import NavbarHead from "../Sub_Layout/NavbarHead";
import EstimateHeadComp from "../EstimateComp/EstimateHeadComp";
import TableComp from "../EstimateComp/TableComp";
import { Col } from "react-bootstrap";
import TableComptwo from "../EstimateComp/TableComptwo";
import AddContactPopup from "../EstimateComp/Poppup/AddContactPopup";
import AddressPopup from "../EstimateComp/Poppup/AddressPopup";
import SalesOrderHeadComp from "../sales_order/SalesOrderHeadComp";
import SalesOrderTableComp from "../sales_order/SalesOrderTableComp";
import SalesOrderTableComptwo from "../sales_order/SalesOrderTableComptwo";
import ViewStockPoppup from "../EstimateComp/ViewStockPoppup";
import DeletePopup from "../Popup/DeletePopup";
import TableDetaile from "../sales_order/TableDetaileComp";
import PaymentDetails from "../sales_order/Poppup/PaymentDetails";
import SalesOrderTrancDetails from "../sales_order/SalesOrderTrancDetails";
import SalesOrderPoUploadPopup from "../sales_order/Poppup/SalesOrderPoUploadPopup";
import SalesOrderPoViewPopup from "../sales_order/Poppup/SalesOrderPoViewPopup";
import SalesOrderEmailUploadPopup from "../sales_order/Poppup/SalesOrderEmailUploadPopup";
import SalesOrderEmailViewPopup from "../sales_order/Poppup/SalesOrderEmailViewPopup";
import EstimateShipWarehouse from "../EstimateComp/EstimateShipWarehouse";
import SalesShipWarehouse from "../sales_order/SalesShipWarehouse";
import AddClientContactPopupCopy from "./AddClientContactPopupCopy";
import SalesAddress from "../sales_order/SalesAddress";
import {
  useCategoryProductViewNewMutation,
  useEstimateAddMutation,
  useEstimateEditMutation,
  useEstimateSizeMutation,
  useEstimateStatusMutation,
  useEstimateTotalUpdateMutation,
  useLazyArtworkDeleteQuery,
  useLazyBoxNoPageListQuery,
  useLazyCategoriesQuery,
  useLazyDeleteProTitleQuery,
  useLazyEstimateTaxQuery,
  useLazyMasterNoPageListQuery,
  useLazyNewCategoryViewQuery,
  useLazyPresentationDeleteQuery,
  useLazyProjectAddressNoPageListQuery,
  useLazyProjectAddressViewQuery,
  useLazyProjectViewQuery,
  usePresentationProAddMutation,
  useProjectAddressAddMutation,
  useProjectAddressEditMutation,
  useProjectAddressListMutation,
  useProjectStatusChangeMutation,
  useProjectTransactionListMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { saveLoader } from "../../redux/slice/loaderNoti";
import moment from "moment/moment";
import { saveAlertMessage } from "../../redux/slice/alertMessage";
import { toast } from "react-hot-toast";

const SalesScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [addContactComp, setAddContactComp] = useState(false);
  const [addAddressComp, setAddAddressComp] = useState(false);
  const [actionBtn, setActionBtn] = useState(false);
  const [addTitle, setAddTitle] = useState(false);
  const [deletes, setDeletes] = useState(false);
  const [viewStock, setViewStock] = useState(false);
  const [addPoUpload, setaddPoUpload] = useState(false);
  const [addPoView, setaddPoView] = useState(false);
  const [addEmailUpload, setaddEmailUpload] = useState(false);
  const [addEmailView, setaddEmailView] = useState(false);

  const [paymentDetail, setPaymentDeta] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [collaboratClick, setCollaboratClick] = useState(true);
  const [fixedCharegeSelected, setFixedCharegeSelected] = useState([]);
  const [fixedCharege, setFixedCharege] = useState([]);
  const [showFixedChargeTotal, setShowFixedChargeTotal] = useState(0);
  const [showFixedChargeGstTotal, setShowFixedChargeGstTotal] = useState(0);

  const [boxRadioBtn, setBoxRadioBtn] = useState(3);
  const [paymentStatus, setPaymentStatus] = useState("Client Paid");

  const [bookedMargin, setBookedMargin] = useState({
    booked_margin: 0,
    cost_price: 0,
    selling_price: 0,
  });

  const [status3, setStatus3] = useState(0);
  const [titleDect, setTitleDect] = useState(false);
  const [displayBtn, setDisplayBtn] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [selectAdd, setSelectAdd] = useState("");
  const [titleObj, setTitleObj] = useState({});

  const onCollaborat = () => {
    setCollaboratClick(!collaboratClick);
  };
  const poppupToggle = () => {
    setAddContactComp(!addContactComp);
  };
  const poppupAddressToggle = () => {
    setAddAddressComp(!addAddressComp);
  };
  const poppupPoToggle = () => {
    setaddPoUpload(!addPoUpload);
  };
  const poppupViewToggle = () => {
    setaddPoView(!addPoView);
  };
  const poppupEmailToggle = () => {
    setaddEmailUpload(!addEmailUpload);
  };
  const poppupEmailViewToggle = () => {
    setaddEmailView(!addEmailView);
  };

  const [tab, setTab] = useState(1);
  const [toggleBtn1, setToggleBtn1] = useState(2);
  const onLinkShow = () => {
    setActionBtn(!actionBtn);
  };

  const deleteToggle = () => {
    setDeletes(!deletes);
  };
  const viewStockToggle = () => {
    setViewStock(!viewStock);
  };
  const PaymenDetTogg = () => {
    setPaymentDeta(!paymentDetail);
  };

  const dispatch = useDispatch();

  // RTK QUERY
  const [masterApi] = useLazyMasterNoPageListQuery();
  const [projectViewApi] = useLazyProjectViewQuery();
  const [estimateAddApi] = useEstimateAddMutation();
  const [estimateEditApi] = useEstimateEditMutation();
  const [estimateSizeApi] = useEstimateSizeMutation();
  const [estimateStatusApi] = useEstimateStatusMutation();
  const [estimateTotalUpdateApi] = useEstimateTotalUpdateMutation();
  const [projectAddressAddApi] = useProjectAddressAddMutation();
  const [projectAddressUpdateApi] = useProjectAddressEditMutation();
  const [projectAddressListApi] = useProjectAddressListMutation();
  const [projectAddressListNoPageListApi] =
    useLazyProjectAddressNoPageListQuery();
  const [projectAddressViewApi] = useLazyProjectAddressViewQuery();
  const [viewCategoryApi] = useLazyNewCategoryViewQuery();
  const [viewCategory] = useCategoryProductViewNewMutation();
  const [presentationProAddApi] = usePresentationProAddMutation();
  const [listCategory] = useLazyCategoriesQuery();
  const [artworkDeleteApi] = useLazyArtworkDeleteQuery();
  const [presentationDeleteApi] = useLazyPresentationDeleteQuery();
  const [estimateTaxApi] = useLazyEstimateTaxQuery();
  const [projectStatusChangeApi] = useProjectStatusChangeMutation();
  const [listBoxApi] = useLazyBoxNoPageListQuery();
  const [projectTransactionListApi] = useProjectTransactionListMutation();
  const [deleteProTitleApi] = useLazyDeleteProTitleQuery();

  const [projectData, setProjectData] = useState({});
  const [projectDetail, setProjectDetail] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [btn, setBtn] = useState(false);
  const [estimate_date, setestimate_date] = useState("");
  const [estimate_dateErr, setestimate_dateErr] = useState(false);
  const [tax_percent, settax_percent] = useState("");
  const [tax_percentErr, settax_percentErr] = useState(false);
  const [start_dateErr, setstart_dateErr] = useState(false);
  const [in_hand_date, setin_hand_date] = useState("");
  const [in_hand_dateErr, setin_hand_dateErr] = useState(false);
  const [introduction, setIntroduction] = useState("");
  const [introductionErr, setIntroductionErr] = useState(false);
  const [terms_and_conditions, setTerms_and_conditions] = useState("");
  const [terms_and_conditionsErr, setTerms_and_conditionsErr] = useState(false);
  const [closing_date, setclosing_date] = useState("");
  const [closing_dateErr, setclosing_dateErr] = useState(false);
  const [presentation_style, setpresentation_style] = useState(0);
  const [presentation_styleErr, setpresentation_styleErr] = useState(false);
  const [pricing_status, setpricing_status] = useState(false);
  const [pricing_statusErr, setpricing_statusErr] = useState(false);
  const [colSpan, setColspan] = useState(1);
  const [defaultTax, setDefaultTax] = useState("");
  const [defaultTaxs, setDefaultTaxs] = useState("");
  const [defaultTaxErr, setDefaultTaxErr] = useState(false);
  const [toggleBtn2, setToggleBtn2] = useState(2);

  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  const [country, setcountry] = useState("");
  const [email_id, setemail_id] = useState("");
  const [land_mark, setland_mark] = useState("");
  const [phone_no, setphone_no] = useState("");
  const [gst, setgst] = useState("");
  const [alternative_phone_no, setalternative_phone_no] = useState("");
  const [type, settype] = useState("");
  const [first_nameErr, setfirst_nameErr] = useState(false);
  const [last_nameErr, setlast_nameErr] = useState(false);
  const [address1Err, setaddress1Err] = useState(false);
  const [address2Err, setaddress2Err] = useState(false);
  const [cityErr, setcityErr] = useState(false);
  const [stateErr, setstateErr] = useState(false);
  const [pincodeErr, setpincodeErr] = useState(false);
  const [countryErr, setcountryErr] = useState(false);
  const [email_idErr, setemail_idErr] = useState(false);
  const [land_markErr, setland_markErr] = useState(false);
  const [phone_noErr, setphone_noErr] = useState(false);
  const [gstErr, setgstErr] = useState(false);
  const [alternative_phone_noErr, setalternative_phone_noErr] = useState(false);
  const [listBillingAddress, setListBillingAddress] = useState([]);
  const [AddressBillingList, setAddressBillingList] = useState([]);
  const [listShippingAddress, setListShippingAddress] = useState([]);
  const [AddressShippingList, setAddressShippingList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [discount_overall, setSubtotal_overall] = useState("");
  const [gstType, setGSTType] = useState(0);
  const [gstTypeErr, setGSTTypeErr] = useState(false);
  const [discountType, setDiscountType] = useState(0);
  const [discountTypes, setDiscountTypes] = useState(0);
  const [discountTypeErr, setDiscountTypeErr] = useState(false);

  const [catogory, setCatogory] = useState("");
  const [dropDown, setDropDown] = useState(null);
  const [searchValueCategory, setSearchValueCategory] = useState("");
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [categoryID, setCategoryID] = useState("");
  const [categoryIDErr, setCategoryIDErr] = useState(false);
  const [category_id, setCategory_id] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [productName, setProductName] = useState([]);
  const [prods, setProds] = useState("");
  const [productID, setProductID] = useState("");
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLists, setSubCategoryLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productLists, setProductLists] = useState([]);
  const [skuCodeList, setSkuCode] = useState([]);
  const [skuSearch, setSearch] = useState("");
  const [sku, setSku] = useState("");
  const [skuErr, setSkuErr] = useState(false);
  const [productData, setProductData] = useState("");
  const [skuID, setSkuID] = useState("");
  const [productsErr, setProductsErr] = useState(false);
  const [subcatogory, setSubcatogory] = useState(false);
  const [catogoryShow2, setCatogoryShow2] = useState(null);
  const [searchValueSubCategory, setSearchSubValueCategory] = useState({});
  const [catogoryName, setCatogoryName] = useState({});
  const [searchValuePro, setSearchValuePro] = useState("");
  const [secondaryCategoryId, setSecondaryCategoryId] = useState("");
  const [customer_po, setcustomer_po] = useState("");
  const [skuList, setSkuList] = useState([]);
  const [status, setStatus] = useState(0);

  const [subtotal, setSubtotal] = useState("");
  const [gstFinal, setGstFinal] = useState("");
  const [gstTax, setGstTax] = useState("");
  const [discount, setDiscount] = useState("");
  const [totalFinal, setTotalFinal] = useState("");
  const [igst, setigst] = useState("");
  const [sgst, setsgst] = useState("");
  const [cgst, setcgst] = useState("");
  const [discount_percent, setdiscount_percent] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const [tax_percentGst, settax_percentGst] = useState("");
  const [tax_percentGst1, settax_percentGst1] = useState("");
  const [billingAddress, setBillingAddress] = useState({});
  const [mainShipAddress, setMainShipAddress] = useState({});

  const [tabs, setTabs] = useState(0);
  const [dynamicAttrs, setDynamicAttrs] = useState([]);
  const [productInformations, setProductInformations] = useState([]);
  const [total_weight, settotal_weight] = useState({});
  const [how_longi, setHow_longi] = useState("");
  const [start_datei, setStart_datei] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [type_of_shipping, setType_of_shipping] = useState("");
  const [shipping_location_id, setshipping_location_id] = useState("");
  const [shipping_type_id, setshipping_type_id] = useState("");
  const [final, setFinal] = useState({});

  const [attributesLists, setAttributesLists] = useState([]);
  const [listAttributess, setListAttributess] = useState([]);
  const [otherProInfos, setOtherProInfos] = useState("");
  const [boxList, setBoxList] = useState([]);

  // OTHER INFORMATION START
  const [dynamicAttr, setDynamicAttr] = useState([]);
  const [listAttributes, setListAttributes] = useState([]);
  const [selectALLProInfo, setSelectALLProInfo] = useState(true);
  const [attributesList, setAttributesList] = useState([]);
  const [otherProInfo, setOtherProInfo] = useState("");
  const [productInformation, setProductInformation] = useState([]);
  const [searchProInfo, setSearchProInfo] = useState("");
  const [otherProInfoErr, setOtherProInfoErr] = useState(false);
  const [productInformationErr, setProductInformationErr] = useState(false);
  // OTHER INFORMATION END

  // OTHER INFORMATION START
  const allSelectProductInfo = () => {
    let ListId = [];
    let ListName = [];
    if (selectALLProInfo) {
      attributesList?.map((item, ind) => {
        ListId.push({ id: item?.id, title: item?.name, value: "" });
        ListName.push(item?.name);
        setOtherProInfo(item?.id);
      });

      setProductInformation(ListId);
      setDynamicAttr(ListName);
    } else {
      setProductInformation(ListId);
      setDynamicAttr(ListName);
      setOtherProInfo("");
    }

    setSelectALLProInfo(!selectALLProInfo);
  };

  const searchProductInfoFun = (event) => {
    let temp = [...attributesList];
    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event?.toLowerCase())
    );

    if (event?.length > 0) {
      setListAttributes(search);
    } else {
      setListAttributes(attributesList);
    }

    setSearchProInfo(event);
  };

  const onDynamicAttributeSelect = (event, id, ind) => {
    let temp = [...productInformation];

    let finder = temp.find((i) => i.id == id);

    if (finder?.id) {
      let inx = temp.findIndex((i) => i.id == id);
      temp.splice(inx, 1);
    } else {
      temp.push({
        id: id,
        title: event,
        value: "",
      });
    }

    setProductInformation(temp);

    if (dynamicAttr.includes(event)) {
      const listtemp = dynamicAttr?.indexOf(event);
      const list = [...dynamicAttr];
      list.splice(listtemp, 1);
      setDynamicAttr(list);
    } else {
      setDynamicAttr([...dynamicAttr, event]);
    }

    setListAttributes(attributesList);
    setSearchProInfo("");

    if (otherProInfo == id) {
      setOtherProInfo("");
    } else {
      setOtherProInfo(id);
    }
  };

  // handle Product Information
  const handleProductInformation = (event, ind) => {
    let temp = [...productInformation];
    temp[ind]["value"] = event;
    setProductInformation(temp);
  };

  // OTHER INFORMATION END

  const getTax = (item) => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      estimateTaxApi(split[1])
        .unwrap()
        .then((res) => {
          if (res?.max_igst) {
            setigst(res?.max_igst);
            setGstTax(res?.max_igst);
            totalUpdate(discount_percent, res?.max_igst, "", item);
          } else {
            setsgst(res?.max_sgst);
            setcgst(res?.max_cgst);
            setGstTax(res?.max_sgst + res?.max_cgst);
            totalUpdate(discount_percent, res?.max_sgst, res?.max_cgst, item);
          }
          if (item?.id) {
            let projectDetail = [];
            item?.projectDetail?.map((item, ind) => {
              if (item?.status == 1) {
                projectDetail.push(item);
              }

              if (item?.status == 2) {
                projectDetail.push(item);
              }
            });
            let temp = projectDetail?.reduce(
              (n, { retail_price }) => n + retail_price,
              0
            );
            setSubtotal(Number(temp).toFixed(2));
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const totalUpdate = (value, tax_percent, tax1_percent, item) => {
    let formdata = new FormData();
    formdata.append("project_id", item?.id);

    let temp = item?.projectDetail?.reduce(
      (n, { retail_price }) => n + retail_price,
      0
    );

    if (tax1_percent) {
      let gsts = tax1_percent + tax_percent;
      let sum = (temp * gsts) / 100;

      formdata.append("tax1_percent", Number(tax1_percent).toFixed(2));
      formdata.append("tax_percent", Number(tax_percent).toFixed(2));
      formdata.append("tax_amount", Number(sum).toFixed(2));
    } else {
      let sum = (temp * tax_percent) / 100;
      formdata.append("tax_percent", Number(tax_percent).toFixed(2));
      formdata.append("tax_amount", Number(sum).toFixed(2));
    }

    if (value) {
      let sum = Number((temp * value) / 100).toFixed(2);
      formdata.append("discount_percent", value);
      formdata.append("discount_amount", +sum);
      setdiscount_percent(value);
      setDiscount(+sum);
    } else {
      setDiscount("");
    }
    setdiscount_percent(value);
    settax_percentGst(tax_percent);
    settax_percentGst1(tax1_percent);
    dispatch(saveLoader(true));
    estimateTotalUpdateApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        getProjectView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  // PROJECT VIEW API
  const getProjectView = () => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      dispatch(saveLoader(true));
      projectViewApi(split[1])
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          if (res?.status == "success") {
            let data = res?.project;
            let findDate = data?.projectDate?.find((i) => i?.type == 5);
            // if (data?.status >= 7 && findDate?.type >= 5) {
            setProjectData(res?.project);
            let arr = [];
            let projectDetail = [];

            data?.projectDetail?.map((item, ind) => {
              arr.push(item?.projectArtwork?.length);

              if (item?.status == 1) {
                projectDetail.push(item);
              }

              if (item?.status == 2) {
                projectDetail.push(item);
              }
            });

            let maxValue = Math.max(...arr);
            setColspan(maxValue);

            let projectPresentationOrder = [];

            data?.projectPresentationOrder?.map((item, ind) => {
              projectPresentationOrder.push(item);
            });

            projectPresentationOrder.sort((a, b) => {
              if (a.order < b.order) return -1;
              if (a.order > b.order) return 1;
              return 0;
            });

            extra_charges_cus(projectPresentationOrder);

            let temp_retail_price = projectDetail?.reduce(
              (n, { retail_price }) => n + retail_price,
              0
            );

            if (data?.tax_type == 2) {
              if (data?.tax_percent) {
                if (data?.tax1_percent) {
                  settax_percentGst1(data?.tax1_percent);
                }
                settax_percentGst(data?.tax_percent);
                setGstTax(data?.tax_percent);
                setDiscount(data?.discount_amount);
                setdiscount_percent(data?.discount_percent);
              } else {
                // getTax(data);
              }

              setTotalFinal(data?.total_amount);
              setSubtotal(Number(temp_retail_price).toFixed(2));
              setGstFinal(data?.tax_total_amount);
            } else if (data?.tax_type == 1) {
              setSubtotal(Number(temp_retail_price).toFixed(2));
              setGstTax(data?.tax_percent + data?.tax1_percent);
              setGstFinal(data?.tax_total_amount);
              setTotalFinal(data?.total_amount);
              setDiscount(data?.discount_amount);
              setdiscount_percent(data?.discount_percent);
            }

            // FOR BOOKED MARGIN
            let cost_price = 0;
            let selling_price = +temp_retail_price;
            projectPresentationOrder?.map((itemPrice, ind) => {
              if (itemPrice?.project_detail_id?.id) {
                let pack = itemPrice?.project_detail_id?.qty;
                let productPrice =
                  itemPrice?.project_detail_id?.productPrice
                    ?.productDistributorPrice;

                if (pack >= 1 && pack <= 25) {
                  cost_price += productPrice?.for_25 * pack;
                } else if (pack >= 26 && pack <= 50) {
                  cost_price += productPrice?.for_50 * pack;
                } else if (pack >= 51 && pack <= 100) {
                  cost_price += productPrice?.for_100 * pack;
                } else if (pack >= 101 && pack <= 150) {
                  cost_price += productPrice?.for_150 * pack;
                } else if (pack >= 151 && pack <= 250) {
                  cost_price += productPrice?.for_250 * pack;
                } else if (pack >= 251 && pack <= 500) {
                  cost_price += productPrice?.for_500 * pack;
                } else if (pack >= 501 && pack <= 1000) {
                  cost_price += productPrice?.for_1000 * pack;
                }
              }
            });

            if (data?.discount_type == 2) {
              selling_price = +selling_price - +data?.discount_amount;
            }

            let booked_margin =
              ((selling_price - cost_price) / selling_price) * 100;

            let booked_margin_percen = Number(booked_margin).toFixed(2);

            setBookedMargin({
              selling_price: selling_price,
              cost_price: cost_price,
              booked_margin: +booked_margin_percen,
            });

            if (data?.in_hand_date) {
              setin_hand_date(data?.in_hand_date);
            }

            let discountAmount = data?.projectDetail?.reduce(
              (n, { discount_amount }) => n + +discount_amount,
              0
            );

            if (discountAmount) {
              setSubtotal_overall(discountAmount);
            }

            if (data?.closing_date) {
              setclosing_date(data?.closing_date);
            }

            if (data?.terms_condition) {
              setTerms_and_conditions(data?.terms_condition);
            }

            if (data?.introduction) {
              setIntroduction(data?.introduction);
            }

            if (data?.presentation_style) {
              setpresentation_style(data?.presentation_style);
            }

            if (data?.pricing_status == 1) {
              setpricing_status(true);
            } else {
              setpricing_status(false);
            }

            if (data?.projectDate?.length > 0) {
              let finder = data?.projectDate?.filter((i) => i?.type == 5);
              setestimate_date(
                moment(finder?.created_date).format("YYYY-MM-DD")
              );
            } else {
              setestimate_date(moment(new Date()).format("YYYY-MM-DD"));
            }

            if (data?.tax_type) {
              setDefaultTax(data?.tax_type);
              setDefaultTaxs(data?.tax_type);
            }

            if (data?.customer_po) {
              setcustomer_po(data?.customer_po);
            }

            if (data?.gst_type) {
              setGSTType(data?.gst_type);
            }

            if (data?.discount_type) {
              setDiscountTypes(data?.discount_type);
              setDiscountType(data?.discount_type);
            }

            let productAttributesName = [];
            let productAttributesID = [];
            data?.EstimateProjectOtherInfo?.map((item, ind) => {
              productAttributesName.push(item?.master?.name);
              setOtherProInfo(item?.id);
              productAttributesID.push({
                info_id: item?.id,
                id: item?.master?.id,
                title: item?.master?.name,
                value: item?.value,
              });
            });
            setDynamicAttr(productAttributesName);
            setProductInformation(productAttributesID);

            if (res?.project?.shipping_or_storage_details?.storing_type) {
              shipping_or_storage_details(
                res?.project?.shipping_or_storage_details
              );
            }

            // FIXED CHARGES
            if (res?.project?.project_fixed_charge?.length > 0) {
              let project_fixed_charge = res?.project?.project_fixed_charge;
              let fixed_ = [];
              let fixed_name = [];
              let toal_fixed_charge = 0; // AMOUNT
              let toal_fixed_charge_gst = 0; // GST AMOUNT
              project_fixed_charge?.map((item, ind) => {
                let obj_fixed = {};
                obj_fixed.id = item?.fixed_charge?.id;
                obj_fixed.amount = item?.amount - item?.tax_amount;
                obj_fixed.tax =
                  +res?.project?.tax_percent + +res?.project?.tax1_percent;
                obj_fixed.tax_amount = item?.tax_amount;
                obj_fixed.tax_total_amount = item?.amount;
                obj_fixed.title = item?.fixed_charge?.name;
                fixed_.push(obj_fixed);
                fixed_name.push(item?.fixed_charge?.name);
                toal_fixed_charge += +item?.amount - item?.tax_amount;
                toal_fixed_charge_gst += +item?.tax_amount;
              });
              setFixedCharegeSelected(fixed_);
              setFixedCharege(fixed_name);
              setShowFixedChargeTotal(toal_fixed_charge);
              setShowFixedChargeGstTotal(toal_fixed_charge_gst);
            }

            // } else if (
            //   (res?.project?.status >= 5 &&
            //     res?.project?.status >= 6 &&
            //     findDate?.type == 5) ||
            //   (data?.status < 7 && findDate?.type <= 5)
            // ) {
            //   dispatch(saveAlertMessage("Estimate Not Completed"));
            //   setTimeout(() => {
            //     navigate(`/estimate?project=${searchParams?.get("project")}`);
            //     saveAlertMessage(null);
            //   }, 1500);
            // } else if (
            //   (res?.project?.status == 3 &&
            //     data?.status < 7 &&
            //     findDate?.type < 5) ||
            //   (res?.project?.status == 4 &&
            //     data?.status < 7 &&
            //     findDate?.type < 5) ||
            //   (res?.project?.status == 5 &&
            //     findDate?.type !== 5 &&
            //     data?.status < 7 &&
            //     findDate?.type < 5)
            // ) {
            //   dispatch(saveAlertMessage("Presentation Not Completed"));
            //   setTimeout(() => {
            //     navigate(
            //       `/presentation?project=${searchParams?.get("project")}`
            //     );
            //     saveAlertMessage(null);
            //   }, 1500);
            // } else {
            //   dispatch(saveAlertMessage("Overview Not Completed"));
            //   setTimeout(() => {
            //     navigate(`/overview?project=${searchParams?.get("project")}`);
            //     saveAlertMessage(null);
            //   }, 1500);
            // }
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  const extra_charges_cus = (project_detail) => {
    if (project_detail?.length > 0) {
      let extra_charges_arr = [];
      project_detail?.map((item, ind) => {
        let temp = [];
        let include_price = item?.project_detail_id?.extra_charges?.filter(
          (i) => +i?.client_price == 1
        );
        let display_to_client = item?.project_detail_id?.extra_charges?.filter(
          (i) => +i?.client_price == 2
        );

        let extra_obj = {};
        include_price?.forEach((price) => {
          if (extra_obj[price.client_price]) {
            extra_obj[price.client_price].final_amount += +price.final_amount;
            extra_obj[price.client_price].net_price += +price.net_price;
            extra_obj[price.client_price].margin += +price.margin;
          } else {
            extra_obj[price.client_price] = {
              name: price?.name,
              client_price: price?.client_price,
              margin: +price?.margin,
              net_price: +price?.net_price,
              final_amount: +price?.final_amount,
            };
          }
        });

        let discount_amount =
          +item?.project_detail_id?.discount_type == 1
            ? +item?.project_detail_id?.discount_amount
            : 0;

        // FOR INCLUDE PRICE
        if (Object.values(extra_obj)?.length > 0) {
          Object.values(extra_obj).map((price, Pind) => {
            let discount_percent = 0;

            if (item?.project_detail_id?.discount_percent) {
              discount_percent =
                (+item?.project_detail_id?.net_price *
                  item?.project_detail_id?.discount_percent) /
                100;
            }

            let discount_value = +item?.project_detail_id?.net_price;
            // let discount_value = +item?.project_detail_id?.net_price - discount_percent;

            // let net_amount = +discount_value - discount_amount;
            let net_amount = +discount_value;

            // let final = +net_amount + +price?.final_amount;
            let final = +net_amount;
            let net_price = +net_amount + +price?.net_price;
            let net_price_;

            let tax =
              item?.project_detail_id?.tax_percent +
              item?.project_detail_id?.tax1_percent;
            let tax_value = (+final * tax) / 100;
            let tax_amount = +final + +tax_value;
            let obj = { ...price };
            obj.margin = +price?.margin;

            if (item?.project_detail_id?.after_discount > 0) {
              net_price_ = item?.project_detail_id?.after_discount;
              // obj.net_price = net_price_ + price?.net_price;
              obj.net_price = net_price_;
              obj.price_per_pack = item?.project_detail_id?.price_per_pack;
            } else {
              net_price_ = item?.project_detail_id?.price_per_pack;
              obj.price_per_pack = +item?.project_detail_id?.price_per_pack;
              obj.net_price = "";
            }

            // obj.final_amount = +discount_value + +price?.final_amount;
            obj.final_amount = +discount_value;
            obj.discount_amount = +discount_amount;
            obj.tax = tax;
            obj.tax_value = +Number(tax_value).toFixed(2);
            obj.tax_amount = +Number(tax_amount).toFixed(2);

            if (item?.project_detail_id?.after_discount) {
              let discountPercentage =
                ((+item?.project_detail_id?.price_per_pack -
                  +item?.project_detail_id?.after_discount) /
                  +item?.project_detail_id?.price_per_pack) *
                100;
              let discountPercentage_ = Number(discountPercentage).toFixed(2);
              obj.dis_percen = +discountPercentage_;
            }

            temp.push(obj);
          });
        } else if (
          item?.project_detail_id?.extra_charges?.length > 0 &&
          Object.values(extra_obj).length == 0
        ) {
          let discount_percent = 0;

          if (item?.project_detail_id?.discount_percent) {
            discount_percent =
              (+item?.project_detail_id?.net_price *
                item?.project_detail_id?.discount_percent) /
              100;
          }

          let discount_value =
            +item?.project_detail_id?.net_price - discount_percent;

          // let net_amount = +discount_value;
          let net_amount = +item?.project_detail_id?.net_price;

          // let final = +net_amount - discount_amount;
          let final = +net_amount;
          let tax =
            item?.project_detail_id?.tax_percent +
            item?.project_detail_id?.tax1_percent;
          let tax_value = (+final * tax) / 100;
          let tax_amount = +final + +tax_value;
          let obj = {};
          obj.margin = +item?.project_detail_id?.margin_percent;
          // obj.net_price = +item?.project_detail_id?.net_price;
          obj.final_amount = +net_amount;
          obj.discount_amount = +discount_amount;
          obj.tax = tax;
          obj.tax_value = +Number(tax_value).toFixed(2);
          obj.tax_amount = +Number(tax_amount).toFixed(2);

          if (item?.project_detail_id?.after_discount > 0) {
            obj.price_per_pack = item?.project_detail_id?.price_per_pack;
            obj.net_price = +item?.project_detail_id?.price_per_pack;
          } else {
            obj.price_per_pack = item?.project_detail_id?.price_per_pack;
            obj.net_price = "";
          }

          if (item?.project_detail_id?.after_discount) {
            let discountPercentage =
              ((+item?.project_detail_id?.price_per_pack -
                +item?.project_detail_id?.after_discount) /
                +item?.project_detail_id?.price_per_pack) *
              100;
            let discountPercentage_ = Number(discountPercentage).toFixed(2);
            obj.dis_percen = +discountPercentage_;
          }

          temp.push(obj);
        }

        // FOR DISPLAY TO CLIENT
        display_to_client?.forEach((price) => {
          let tax =
            item?.project_detail_id?.tax_percent +
            item?.project_detail_id?.tax1_percent;
          let tax_value = (+price?.final_amount * tax) / 100;
          let tax_amount = +price?.final_amount + +tax_value;
          let obj = { ...price };
          obj.margin = +price?.margin;
          obj.final_amount = +price?.final_amount;
          obj.tax = tax;
          obj.tax_value = +Number(tax_value).toFixed(2);
          obj.tax_amount = +Number(tax_amount).toFixed(2);

          if (item?.project_detail_id?.after_discount > 0) {
            obj.net_price = +price?.net_price;
          } else {
            obj.price_per_pack = +price?.net_price;
            obj.net_price = "";
          }

          temp.push(obj);
        });

        let prject_img_url = "";

        if (item?.project_detail_id?.product_sku?.color_id) {
          let finder = item?.project_detail_id?.product?.colorImages?.find(
            (i) => i?.color_id == item?.project_detail_id?.product_sku?.color_id
          );
          prject_img_url = finder?.image_url;
        } else {
          prject_img_url = item?.project_detail_id?.product?.defaultImage;
        }

        let extra_charges_obj = {
          ...item,
          prject_img_url: prject_img_url,
        };

        let project_detail_id = {
          ...item?.project_detail_id,
        };

        if (temp?.length > 0) {
          project_detail_id.extra_charges = temp;
        } else {
          project_detail_id.extra_charges = temp;

          if (+item?.project_detail_id?.discount_type == 1) {
            let discount_amount_ = item?.project_detail_id?.discount_amount
              ? +item?.project_detail_id?.discount_amount
              : 0;

            let net_price_ = +item?.project_detail_id?.net_price;

            let tax_ =
              +item?.project_detail_id?.tax1_percent +
              +item?.project_detail_id?.tax_percent;

            let minus_price_ = net_price_ - discount_amount_;

            let tax_value_ = (minus_price_ * tax_) / 100;

            let tax_price_ = tax_value_ + minus_price_;

            // project_detail_id.net_amount = minus_price_;
            // project_detail_id.tax_amount = tax_value_;
            // project_detail_id.final_amount = tax_price_
          }
        }

        extra_charges_obj.project_detail_id = project_detail_id;

        extra_charges_arr.push(extra_charges_obj);
      });

      let filters = [];

      extra_charges_arr?.map((item, ind) => {
        if (item?.project_detail_id?.status == 1) {
          filters.push(item);
        }

        if (item?.project_detail_id?.status == 2) {
          filters.push(item);
        }

        if (item?.title) {
          let obj = {};
          obj.id = item?.id;
          obj.order = item?.order;
          obj.prject_img_url = null;
          obj.project_detail_id = null;
          obj.project_id = item?.project_id;
          obj.title = item?.title;
          filters.push(obj);
        }
      });

      filters.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });

      setProjectDetails(filters);

      let start_filter = [];

      filters?.map((item, ind) => {
        if (
          item?.project_detail_id?.status >= 1 &&
          item?.project_detail_id?.status <= 2
        ) {
          start_filter.push(item);
        }

        if (item?.title && !item?.project_detail_id) {
          start_filter.push(item);
        }
      });

      start_filter.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });

      setProjectDetail(start_filter);
      // console.log("start_filter", start_filter);
      setToggleBtn2(2);
    }
  };

  // GET MASTER
  const getMaster = () => {
    masterApi()
      .unwrap()
      .then((res) => {
        setListAttributes(res?.estimate_attributes);
        setAttributesList(res?.estimate_attributes);
      })
      .catch((err) => {
        console.log("err", err);
      });

    listBoxApi()
      .unwrap()
      .then((res) => {
        let temp = [];

        res?.data?.map((item, ind) => {
          temp.push({
            box_id: item?.id,
            box_detail: {
              ...item,
              actual_name: item?.name,
              name: `${item?.name}-${item?.model}-${item?.dimension}`,
            },
            qty: "",
          });
        });
        setBoxList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // GET ADDRESS
  const getAddressList = () => {
    let project_code = searchParams?.get("project");
    let split = project_code.split("_");
    projectAddressListNoPageListApi(split[1])
      .unwrap()
      .then((res) => {
        let billig = res?.project_address?.filter((i) => i?.type == 2);
        let shipping = res?.project_address?.filter((i) => i?.type == 1);
        setListBillingAddress(billig);
        setBillingAddress(billig?.[billig?.length - 1]);
        setAddressBillingList(billig);
        setListShippingAddress(shipping);
        setAddressShippingList(shipping);
        setMainShipAddress(shipping?.[shipping?.length - 1]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const shipping_or_storage_details = (data) => {
    if (data?.storing_type == 3) {
      setTabs(data?.storing_type);
      setType_of_shipping(1);
    } else {
      setTabs(data?.storing_type);
      setType_of_shipping(data?.storing_type);
    }
    if (data?.shipping_location_id) {
      setshipping_location_id(data?.shipping_location_id);
    }

    if (data?.shipping_type_id) {
      setshipping_type_id(data?.shipping_type_id);
    }

    // if (data?.shipping_type) {
    //   setType_of_shipping(data?.shipping_type);
    // }

    if (data?.storage_no_of_days) {
      setHow_longi(data?.storage_no_of_days);
    }

    if (data?.storage_start_date) {
      setStart_datei(data?.storage_start_date);
    }

    if (data?.shipping_storage_amount) {
      let obj = {
        price_details: data?.price_details,
        total_price: data?.shipping_storage_amount,
      };
      setFinal(obj);
    }

    let boxes = {};
    data?.boxes?.map((item, ind) => {
      boxes[item?.box_id] = item?.qty;
    });

    let temp = [];

    if (data?.storing_type == 3) {
      temp = boxList;
      setAttributesLists(boxList);
      setListAttributess(boxList);
    } else {
      temp = boxList?.filter((i) => i?.box_detail?.type == data?.shipping_type);
      setAttributesLists(temp);
      setListAttributess(temp);
    }

    let finalArr = [];
    let ListName = [];
    let responce = [];

    temp?.map((item, ind) => {
      let obj = {
        ...item,
      };

      if (boxes[item?.box_id]) {
        obj.qty = boxes[item?.box_id];

        ListName.push(item.box_detail?.name);
      }

      finalArr.push(obj);
    });

    finalArr?.map((item, ind) => {
      if (item?.qty) {
        responce.push({
          id: item?.box_id,
          title: item?.box_detail?.name,
          qty: item?.qty,
        });
        setOtherProInfos(item?.box_id);
      }
    });
    settotal_weight({ total_weight: data?.total_volumetric_weight });
    setProductInformations(responce);
    setDynamicAttrs(ListName);
  };

  const getProjectTransactionList = () => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      let split = project_code.split("_");
      let formdata = new FormData();
      formdata.append("project_id", split[1]);
      dispatch(saveLoader(true));
      projectTransactionListApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          if (res?.status == "success") {
            if (Array.isArray(res?.data)) {
              setTransactionList(res?.data);
            } else {
              if (res?.data?.original?.message) {
                dispatch(saveAlertMessage(res?.data?.original?.message));
              }
            }
          }
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    let project_code = searchParams?.get("project");
    if (project_code) {
      getProjectView();
      getAddressList();
      getProjectTransactionList();
    }

    getMaster();
  }, []);

  useEffect(() => {
    if (
      boxList?.length > 0 &&
      projectData?.shipping_or_storage_details?.storing_type
    ) {
      shipping_or_storage_details(projectData?.shipping_or_storage_details);
    }
  }, [boxList, projectData?.shipping_or_storage_details?.storing_type]);

  const showAddress = (value) => {
    settype(value);
    setAddContactComp(true);
  };

  const closePopup = () => {
    setaddress1Err(false);
    setaddress2Err(false);
    setland_markErr(false);
    setpincodeErr(false);
    setcityErr(false);
    setstateErr(false);
    setphone_noErr(false);
    setalternative_phone_noErr(false);
    setfirst_nameErr(false);
    setlast_nameErr(false);
    setemail_idErr(false);
    setcountryErr(false);
    setgstErr(false);
    settype("");
    setfirst_name("");
    setlast_name("");
    setaddress1("");
    setaddress2("");
    setcity("");
    setstate("");
    setpincode("");
    setcountry("");
    setemail_id("");
    setland_mark("");
    setphone_no("");
    setgst("");
    setalternative_phone_no("");
    setAddContactComp(false);
  };

  const closeAddPro = () => {
    setCatogory("");
    setShowPopup(false);
    setDropDown(null);
    setCategoryIDErr(false);
    setSubcatogory(false);
    setSubCategoryList([]);
    setCatogoryShow2(null);
    setProds("");
    setProductID("");
    setProductList([]);
    setProductLists([]);
    setProductsErr(false);
    setSkuList([]);
    setSku("");
    setSkuErr(false);
    setShowPopup(false);
  };

  // STORE ADDRESS
  const submitAddress = () => {
    if (
      address1?.length == 0 ||
      address2?.length == 0 ||
      land_mark?.length == 0 ||
      pincode?.length == 0 ||
      city?.length == 0 ||
      state?.length == 0 ||
      country?.length == 0 ||
      phone_no?.length == 0 ||
      alternative_phone_no?.length == 0 ||
      first_name?.length == 0 ||
      last_name?.length == 0 ||
      email_id?.length == 0
    ) {
      setaddress1Err(true);
      setaddress2Err(true);
      setland_markErr(true);
      setpincodeErr(true);
      setcityErr(true);
      setstateErr(true);
      setphone_noErr(true);
      setalternative_phone_noErr(true);
      setfirst_nameErr(true);
      setlast_nameErr(true);
      setemail_idErr(true);
      setcountryErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("project_id", projectData?.id);
      formdata.append("first_name", first_name);
      formdata.append("last_name", last_name);
      formdata.append("address1", address1);
      formdata.append("address2", address2);
      formdata.append("city", city);
      formdata.append("state", state);
      formdata.append("pincode", pincode);
      formdata.append("country", country);
      formdata.append("email_id", email_id);
      formdata.append("landmark", land_mark);
      formdata.append("phone_no", phone_no);
      formdata.append("alter_phone_no", alternative_phone_no);
      formdata.append("type", type);

      if (gst) {
        formdata.append("gst_no", gst);
      }

      dispatch(saveLoader(true));
      setBtn(true);
      projectAddressAddApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          getAddressList();
          closePopup();
          toast.success(res?.message);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // ARTWORK DELETE API
  const artworkDelete = (id) => {
    dispatch(saveLoader(true));
    artworkDeleteApi(id)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          getProjectView();
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  // ESTIMATE STATUS
  const estimateStatus = (id, status) => {
    let formdata = new FormData();
    formdata.append("project_detail_id", id);
    formdata.append("estimate_status", status);
    dispatch(saveLoader(true));
    estimateStatusApi(formdata)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          getProjectView();
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const getTitleDelete = (id) => {
    dispatch(saveLoader(true));
    setBtn(true);
    deleteProTitleApi(id)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setBtn(false);
        getProjectView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setBtn(false);
        console.log("err", err);
      });
  };

  const closeAddPopup = () => {
    setTitle("");
    setTitleErr(false);
    setPopupShow(false);
    setSelectAdd("");
    setTitleObj({});
  };

  const getTitle = (item) => {
    setTitle(item?.title);
    setTitleObj(item);
    setSelectAdd(1);
    setPopupShow(true);
  };

  const deletePopupOpenTitle = (id) => {
    setStatus3(id);
    setTitleDect(!titleDect);
  };

  const totalSum = (sub_total, project_data, fixed_charge_total) => {
    let amount = sub_total;

    if (project_data?.shipping_storage_amount) {
      amount += +project_data?.shipping_storage_amount;
    }

    if (project_data?.discount_type == 1) {
      let discountAmount = project_data?.projectDetail?.reduce(
        (n, { discount_amount }) => n + +discount_amount,
        0
      );

      // amount = +amount - +discountAmount;
    }

    if (project_data?.discount_type == 2) {
      amount = +amount - +project_data?.discount_amount;
    }

    if (fixed_charge_total) {
      amount += +fixed_charge_total;
    }

    let price = Number(amount).toFixed(2);

    return price.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
  };

  const overallDiscount = (event, item) => {
    let temp = item?.projectDetail?.reduce(
      (n, { retail_price }) => n + +retail_price,
      0
    );
    let sum = Number((+temp * +event) / 100).toFixed(2);
    setdiscount_percent(event);
    setDiscount(+sum);
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div className="dashRightView2 overview_pages overflow-scroll ">
      {viewStock && <ViewStockPoppup viewStockToggle={viewStockToggle} />}
      {deletes && (
        <DeletePopup deleteToggle={deleteToggle} setAddTitle={setAddTitle} />
      )}
      {/* <PaymentDetails /> */}
      <div className=" d-flex justify-content-end position-relative mb-2">
        <div className="action_overview mx-4 mb-5">
          <NavbarHead onLinkShow={onLinkShow} projectData={projectData} />
          {actionBtn && (
            <div
              className="invisible-cont2"
              style={{ zIndex: 200 }}
              onClick={() => setActionBtn(false)}
            />
          )}
          {actionBtn && (
            <div className="action_btn ml-1" style={{ zIndex: 300 }}>
              <button
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
                onClick={() => {
                  setActionBtn(false);
                }}
              >
                Create Invoice
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Copy Sales Order
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Preview Sales Order
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 text-dark bg-white text-start f2 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                Email Sales Order
              </button>
              <button
                onClick={() => setActionBtn(false)}
                className="border-0 bg-white text-start f2 text-dark fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  py-2 px-2 hover_eff w-100"
              >
                View Portal
              </button>
            </div>
          )}
        </div>
      </div>
      {/* {addContactComp && <AddContactPopup poppupToggle={poppupToggle} />} 16-09-2024 */}
      {addContactComp && (
        <AddClientContactPopupCopy
          closePopup={closePopup}
          poppupToggle={poppupToggle}
          first_name={first_name}
          setfirst_name={setfirst_name}
          last_name={last_name}
          setlast_name={setlast_name}
          address1={address1}
          setaddress1={setaddress1}
          address2={address2}
          setaddress2={setaddress2}
          city={city}
          setcity={setcity}
          state={state}
          setstate={setstate}
          pincode={pincode}
          setpincode={setpincode}
          country={country}
          setcountry={setcountry}
          email_id={email_id}
          setemail_id={setemail_id}
          land_mark={land_mark}
          setland_mark={setland_mark}
          phone_no={phone_no}
          setphone_no={setphone_no}
          gst={gst}
          setgst={setgst}
          alternative_phone_no={alternative_phone_no}
          setalternative_phone_no={setalternative_phone_no}
          first_nameErr={first_nameErr}
          last_nameErr={last_nameErr}
          address1Err={address1Err}
          address2Err={address2Err}
          cityErr={cityErr}
          stateErr={stateErr}
          pincodeErr={pincodeErr}
          countryErr={countryErr}
          email_idErr={email_idErr}
          land_markErr={land_markErr}
          phone_noErr={phone_noErr}
          gstErr={gstErr}
          alternative_phone_noErr={alternative_phone_noErr}
          submitAddress={submitAddress}
          btn={btn}
        />
      )}

      {addPoView && (
        <SalesOrderPoViewPopup poppupViewToggle={poppupViewToggle} />
      )}

      {addPoUpload && (
        <SalesOrderPoUploadPopup poppupPoToggle={poppupPoToggle} />
      )}
      {addEmailUpload && (
        <SalesOrderEmailUploadPopup poppupEmailToggle={poppupEmailToggle} />
      )}
      {addEmailView && (
        <SalesOrderEmailViewPopup
          poppupEmailViewToggle={poppupEmailViewToggle}
        />
      )}
      {addAddressComp && (
        <AddressPopup poppupAddressToggle={poppupAddressToggle} />
      )}
      <SalesOrderHeadComp
        showAddress={showAddress}
        PaymenDetTogg={PaymenDetTogg}
        setPaymentDeta={setPaymentDeta}
        paymentDetail={paymentDetail}
        onCollaborat={onCollaborat}
        collaboratClick={collaboratClick}
        setAddContComp={setAddContactComp}
        poppupAddressToggle={poppupAddressToggle}
        boxRadioBtn={boxRadioBtn}
        setBoxRadioBtn={setBoxRadioBtn}
        poppupPoToggle={poppupPoToggle}
        poppupViewToggle={poppupViewToggle}
        poppupEmailToggle={poppupEmailToggle}
        poppupEmailViewToggle={poppupEmailViewToggle}
        handleProductInformation={handleProductInformation}
        productInformationErr={productInformationErr}
        productInformation={productInformation}
        dynamicAttr={dynamicAttr}
        searchProductInfoFun={searchProductInfoFun}
        searchProInfo={searchProInfo}
        listBillingAddress={listBillingAddress}
        setListBillingAddress={setListBillingAddress}
        AddressBillingList={AddressBillingList}
        listShippingAddress={listShippingAddress}
        setListShippingAddress={setListShippingAddress}
        AddressShippingList={AddressShippingList}
        allSelectProductInfo={allSelectProductInfo}
        listAttributes={listAttributes}
        onDynamicAttributeSelect={onDynamicAttributeSelect}
        otherProInfo={otherProInfo}
        otherProInfoErr={otherProInfoErr}
        setIntroduction={setIntroduction}
        introduction={introduction}
        introductionErr={introductionErr}
        setTerms_and_conditions={setTerms_and_conditions}
        terms_and_conditions={terms_and_conditions}
        terms_and_conditionsErr={terms_and_conditionsErr}
        setin_hand_date={setin_hand_date}
        in_hand_date={in_hand_date}
        in_hand_dateErr={in_hand_dateErr}
        setclosing_date={setclosing_date}
        closing_dateErr={closing_dateErr}
        closing_date={closing_date}
        setestimate_date={setestimate_date}
        estimate_date={estimate_date}
        estimate_dateErr={estimate_dateErr}
        setDefaultTax={setDefaultTax}
        defaultTax={defaultTax}
        defaultTaxs={defaultTaxs}
        defaultTaxErr={defaultTaxErr}
        setcustomer_po={setcustomer_po}
        customer_po={customer_po}
        setBillingAddress={setBillingAddress}
        billingAddress={billingAddress}
        mainShipAddress={mainShipAddress}
        setMainShipAddress={setMainShipAddress}
        paymentStatus={paymentStatus}
        setPaymentStatus={setPaymentStatus}
        setGSTType={setGSTType}
        gstType={gstType}
        gstTypeErr={gstTypeErr}
        setDiscountType={setDiscountType}
        discountType={discountType}
        discountTypeErr={discountTypeErr}
      />
      <div className="mt-3 d-flex ac-jb mt-5 flex-column flex-md-row">
        <div className="d-flex ac-js">
          <button
            onClick={() => setTab(1)}
            className={`${
              tab == 1 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
          >
            Products
          </button>
          <button
            onClick={() => setTab(2)}
            className={`${
              tab == 2 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
          >
            Artwork
          </button>
          <button
            onClick={() => setTab(4)}
            className={`${
              tab == 4 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
          >
            Shipping and Warehouse
          </button>
          <button
            onClick={() => setTab(5)}
            className={`${
              tab == 5 ? "border_click_cust" : "cust-btn "
            } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
          >
            Address
          </button>
          {boxRadioBtn == 3 && (
            <button
              onClick={() => setTab(3)}
              className={`${
                tab == 3 ? "border_click_cust" : "cust-btn "
              } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
            >
              Transaction Details
            </button>
          )}
        </div>
        {tab === 1 && (
          <div className="d-flex ac-jc gap-3 mt-3 mt-md-0 flex-column flex-sm-row ">
            {/* {tab == 1 && ( */}
            <div className="d-flex">
              <button
                onClick={() => setToggleBtn1(1)}
                className={` ${
                  toggleBtn1 === 1 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_left py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              >
                Compacts
              </button>
              <button
                onClick={() => setToggleBtn1(2)}
                className={` ${
                  toggleBtn1 === 2 && "primary bg-lt-blue"
                } editBtn bg-transparent text-nowrap rounded_cust_right border-start-0 py-2 px-3 f1 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 `}
              >
                Detailed
              </button>
            </div>
          </div>
        )}
      </div>

      {tab === 1 && toggleBtn1 === 1 && (
        <SalesOrderTableComp
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          projectData={projectData}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
          projectDetail={projectDetail}
          setProjectDetail={setProjectDetail}
          setShowPopup={setShowPopup}
          estimateStatus={estimateStatus}
          status={status}
          setStatus={setStatus}
          setDeletes={setDeletes}
          deletes={deletes}
          billingAddress={billingAddress}
          setPopupShow={setPopupShow}
          displayBtn={displayBtn}
          setDisplayBtn={setDisplayBtn}
          getTitle={getTitle}
          getTitleDelete={getTitleDelete}
          getProjectView={getProjectView}
          setStatus3={setStatus3}
          deletePopupOpenTitle={deletePopupOpenTitle}
        />
      )}
      {tab === 1 && toggleBtn1 !== 1 && (
        <TableDetaile
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          projectData={projectData}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
          projectDetail={projectDetail}
          setProjectDetail={setProjectDetail}
          setShowPopup={setShowPopup}
          estimateStatus={estimateStatus}
          status={status}
          setStatus={setStatus}
          setDeletes={setDeletes}
          deletes={deletes}
          billingAddress={billingAddress}
          setPopupShow={setPopupShow}
          displayBtn={displayBtn}
          setDisplayBtn={setDisplayBtn}
          getTitle={getTitle}
          getTitleDelete={getTitleDelete}
          getProjectView={getProjectView}
          setStatus3={setStatus3}
          deletePopupOpenTitle={deletePopupOpenTitle}
        />
      )}
      {tab === 2 && (
        <SalesOrderTableComptwo
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          projectData={projectData}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
          projectDetail={projectDetail}
          setProjectDetail={setProjectDetail}
          colSpan={colSpan}
          setColspan={setColspan}
          getProjectView={getProjectView}
          setShowPopup={setShowPopup}
          artworkDelete={artworkDelete}
        />
      )}

      {tab === 4 && (
        <SalesShipWarehouse
          getProjectView={getProjectView}
          projectData={projectData}
          setTab={setTabs}
          tab={tabs}
          setDynamicAttr={setDynamicAttrs}
          dynamicAttr={dynamicAttrs}
          productInformation={productInformations}
          setProductInformation={setProductInformations}
          total_weight={total_weight}
          settotal_weight={settotal_weight}
          how_longi={how_longi}
          setHow_longi={setHow_longi}
          start_datei={start_datei}
          setStart_datei={setStart_datei}
          type_of_shipping={type_of_shipping}
          setType_of_shipping={setType_of_shipping}
          shipping_location_id={shipping_location_id}
          setshipping_location_id={setshipping_location_id}
          shipping_type_id={shipping_type_id}
          setshipping_type_id={setshipping_type_id}
          final={final}
          setFinal={setFinal}
          attributesList={attributesLists}
          setAttributesList={setAttributesLists}
          listAttributes={listAttributess}
          setListAttributes={setListAttributess}
          boxList={boxList}
          setBoxList={setBoxList}
          setOtherProInfo={setOtherProInfos}
          otherProInfo={otherProInfos}
          hide_content={true}
        />
      )}
      {tab === 5 && (
        <SalesAddress
          projectData={projectData}
          getAddressList={getAddressList}
          getTax={getTax}
          getProjectView={getProjectView}
        />
      )}

      {tab === 3 && (
        <SalesOrderTrancDetails
          setAddTitle={setAddTitle}
          toggleBtn1={toggleBtn1}
          tab={tab}
          deleteToggle={deleteToggle}
          viewStockToggle={viewStockToggle}
          transactionList={transactionList}
          getProjectTransactionList={getProjectTransactionList}
        />
      )}

      {addTitle && (
        <div className="presen-list mb-3">
          <div className="add-tit-btn presen-detail presen-list mt-3">
            <div className="m-2">
              <input
                placeholder="Add a title"
                className="w-100 b-color-add mt-3"
              />
              <div className="d-flex mt-2 position-absolute m-2">
                <div className="dropdown mx-2">
                  <button
                    className="add-tit-btn2 mx-1 bottom_show_btn"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    + Add
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span className="dropdown-item">Product</span>
                    </li>
                    <li>
                      <span className="dropdown-item">Tittle</span>
                    </li>
                  </ul>
                </div>
                {/* <button className="add-tit-btn1">+ Add</button> */}
                <button
                  className="add-tit-btn2 mx-1 bottom_show_btn"
                  onClick={() => setAddShow(!addShow)}
                >
                  {addShow ? "Show" : "hide"}
                </button>
                <button
                  className="add-tit-btn3 mx-1 bottom_delete_btn"
                  onClick={() => setDeletes(!deletes)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {tab !== 3 && tab !== 4 && tab !== 5 && (
        <div className="d-flex as-jb flex-column flex-md-row gap-3 w-100 mt-5">
          <div class="summary bg-soft-gray1 w-35 md_t_W">
            <div className="d-flex ac-jb py-3 px-1 px-md-3">
              <p className="">Selling Price</p>
              <p className="">
                {bookedMargin?.selling_price
                  ? "₹" + commaValue(bookedMargin?.selling_price)
                  : ""}
              </p>
            </div>
            <div className="d-flex ac-jb py-3 px-1 px-md-3">
              <p className="">Cost Price</p>
              <p className="">
                {bookedMargin?.cost_price
                  ? "₹" + commaValue(bookedMargin?.cost_price)
                  : ""}
              </p>
            </div>
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <p className="">Booked Margin</p>
              <p className="">
                {bookedMargin?.booked_margin
                  ? "₹" + commaValue(bookedMargin?.booked_margin)
                  : ""}
              </p>
            </div>
            <div className="d-flex ac-jb px-1 px-md-3 py-3">
              <p className="">Booked Margin Amount</p>
              <p className="">
                {`₹${commaValue(
                  Number(
                    bookedMargin?.selling_price - bookedMargin?.cost_price
                  ).toFixed(2)
                )}`}
              </p>
            </div>
          </div>
          {tab == 1 && (
            <div class="summary bg-soft-gray1 w-45 md_t_W ">
              {discountType == 1 && (
                <div className="d-flex ac-jb py-3 px-1 px-md-3">
                  <p className="">Discount Amount</p>
                  <p className="">
                    {discount_overall
                      ? "₹" + commaValue(discount_overall)
                      : "0"}
                  </p>
                </div>
              )}
              <div className="d-flex ac-jb py-3 px-1 px-md-3">
                <p className="">Subtotal</p>
                <p className="">
                  {subtotal ? "₹" + commaValue(subtotal) : "0"}
                </p>
              </div>
              {discountType == 2 && (
                <>
                  <div className="d-flex ac-jb px-1 px-md-3 py-3">
                    <div className="d-flex flex-column">
                      <p className="">Discount % </p>
                      <input
                        onChange={(e) =>
                          overallDiscount(e.target.value, projectData)
                        }
                        type={"number"}
                        value={discount_percent}
                      />
                      {/* <div className="text-start">
                        <button
                          role={"button"}
                          className={`f2 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 cust-btn mt-2 primary f3 ${
                            btn ? "opacity-50" : ""
                          }`}
                          onClick={() =>
                            totalUpdate(
                              discount_percent,
                              tax_percentGst,
                              tax_percentGst1,
                              projectData
                            )
                          }
                          disabled={btn}
                        >
                          Submit
                        </button>
                      </div> */}
                    </div>
                    <p className="">
                      {discount
                        ? "₹" + commaValue(Number(discount).toFixed(2))
                        : "0"}
                    </p>
                  </div>

                  <div className="d-flex ac-jb py-3 px-1 px-md-3">
                    <p className="">Overall Subtotal</p>
                    <p className="">
                      {subtotal && discount
                        ? `₹${commaValue(
                            Number(+subtotal - +discount).toFixed(2)
                          )}`
                        : subtotal
                        ? `₹${commaValue(Number(+subtotal).toFixed(2))}`
                        : "0"}
                    </p>
                  </div>
                </>
              )}
              {fixedCharegeSelected?.map((item, ind) => {
                return (
                  <div className="d-flex ac-jb py-3 px-1 px-md-3" key={ind}>
                    <div className="col-md-4">
                      <p className="">
                        {item?.title} ({item?.tax}%)
                      </p>
                    </div>
                    <div className="col-md-7 d-flex ac-jc gap-1">
                      <input
                        placeholder="Amount *"
                        className="editBtn w-100 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        //  onChange={(e) =>
                        //    handleFixedCharege(e.target.value, ind)
                        //  }
                        type={"number"}
                        value={item?.amount}
                      />
                      <input
                        placeholder="Total Amount *"
                        className="editBtn w-100 rounded-3 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                        type={"number"}
                        value={item?.tax_total_amount}
                        readOnly
                      />
                    </div>
                  </div>
                );
              })}
              <div className="d-flex ac-jb py-3 px-1 px-md-3">
                <p className="">Fixed Charge Amount</p>
                <p className="">
                  {showFixedChargeTotal
                    ? `₹${commaValue(Number(+showFixedChargeTotal).toFixed(2))}`
                    : "0"}
                </p>
              </div>
              <div className="d-flex ac-jb py-3 px-1 px-md-3">
                <p className="">Shipping Amount</p>
                <p className="">
                  {projectData?.shipping_storage_amount
                    ? "₹" + commaValue(projectData?.shipping_storage_amount)
                    : "0"}
                </p>
              </div>
              <div className="d-flex ac-jb px-1 px-md-3 py-3">
                <p className="">Total</p>
                <p className="">
                  ₹{totalSum(+subtotal, projectData, +showFixedChargeTotal)}
                </p>
              </div>

              <div className="d-flex ac-jb px-1 px-md-3 py-3">
                <p className="">Products GST</p>
                <p className="">
                  {gstFinal
                    ? "₹" + commaValue(Number(+gstFinal).toFixed(2))
                    : "0"}
                </p>
              </div>
              <div className="d-flex ac-jb px-1 px-md-3 py-3">
                <p className="">Shipping GST</p>
                <p className="">
                  {projectData?.shipping_storage_tax
                    ? `₹${commaValue(
                        Number(+projectData?.shipping_storage_tax).toFixed(2)
                      )}`
                    : "0"}
                </p>
              </div>
              <div className="d-flex ac-jb px-1 px-md-3 py-3">
                <p className="">Fixed Charges GST</p>
                <p className="">
                  {showFixedChargeGstTotal
                    ? `₹${commaValue(
                        Number(+showFixedChargeGstTotal).toFixed(2)
                      )}`
                    : "0"}
                </p>
              </div>
              <div className="d-flex ac-jb px-1 px-md-3 py-3">
                <p className="">Total GST</p>
                <p className="">
                  {gstFinal && projectData?.shipping_storage_tax >= 0
                    ? `₹${commaValue(
                        Number(
                          +gstFinal +
                            +projectData?.shipping_storage_tax +
                            +showFixedChargeGstTotal
                        ).toFixed(2)
                      )}`
                    : "0"}
                </p>
              </div>
              <div className="d-flex ac-jb px-1 px-md-3 py-3">
                <p className="">Final Amount</p>
                <p className="">
                  {totalFinal ? "₹" + commaValue(totalFinal) : "0"}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SalesScreen;
