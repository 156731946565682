import React, { useEffect, useState } from "react";
import ProductSearchHeader from "../components/ProductComp/ProductSearchHeader";
import CachedIcon from "@mui/icons-material/Cached";
import ErrorIcon from "@mui/icons-material/Error";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  useAdminListCustomerReqMutation,
  useEstimateMarginReqMutation,
  useLazyPresentationProViewQuery,
  useProjectListMutation,
  useProjectMarginRequestMutation,
  useProjectMarginUpdateMutation,
} from "../redux/api/api";
import { viewIcon } from "../assets/img";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

const ProjectsRequestPrice = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [addProject, setAddProject] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [btn, setBtn] = useState(false);
  const [presentation_style, setpresentation_style] = useState(0);
  const [presentation_styleErr, setpresentation_styleErr] = useState(false);

  // RTK QUERY
  const [presentationProViewApi] = useLazyPresentationProViewQuery();
  const [ProjectReqListApi] = useProjectMarginRequestMutation();
  const [ProjectUpdateApi] = useProjectMarginUpdateMutation();
  const [customerAdminApi] = useAdminListCustomerReqMutation();
  const [estimateMarginReqApi] = useEstimateMarginReqMutation();

  const [adminList, setAdminList] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [margin_percent, setmargin_percent] = useState("");
  const [reqPrice, setReqPrice] = useState({});
  const [margin_percentErr, setmargin_percentErr] = useState(false);
  const [projectViewData, setProjectViewData] = useState({});
  const [searchs, setsearchs] = useState("");
  const [rows, setrows] = useState("");
  const [orders, setorders] = useState("");
  const [order_reps, setorder_reps] = useState("");
  const [client_reps, setclient_reps] = useState("");
  const [statuss, setstatuss] = useState("");
  const [codes, setcodes] = useState("");
  const [production_reps, setproduction_reps] = useState("");
  const [product_qty, setproduct_qty] = useState(25);
  const [beforeDiscount, setBeforeDiscount] = useState("");
  const [afterDiscount, setAfterDiscount] = useState("");

  // PROJECT PROJECT LIST
  const getProjectList = (
    search,
    row,
    order,
    order_rep,
    client_rep,
    status,
    code,
    production_rep
  ) => {
    let formdata = new FormData();
    let params = `?page=${page}`;

    if (row) {
      formdata.append("rows", row);
    }

    if (search) {
      formdata.append("search", search);
    }

    if (order) {
      formdata.append("order", order);
    }

    if (order_rep) {
      formdata.append("order_rep", order_rep);
    }
    if (client_rep) {
      formdata.append("client_rep", client_rep);
    }
    if (status) {
      formdata.append("status", status);
    }

    if (code) {
      formdata.append("code", code);
    }
    if (production_rep) {
      formdata.append("production_rep", production_rep);
    }

    formdata.append("price_status_type", 2);

    setsearchs(search);
    setrows(row);
    setorders(order);
    setorder_reps(order_rep);
    setclient_reps(client_rep);
    setstatuss(status);
    setcodes(code);
    setproduction_reps(production_rep);

    ProjectReqListApi(formdata)
      .unwrap()
      .then((res) => {
        let temp = [];
        res?.lists?.map((item, ind) => {
          item?.projectDetail?.map((pro, inpro) => {
            if (pro?.price_status == 1) {
              temp.push({
                ...pro,
                project_list: item,
              });
            }
          });
        });

        // console.log("temp", temp);

        setList(temp);
        // setPagination(res?.pagination_meta);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // ADMIN LIST
  const getViewAdmin = () => {
    customerAdminApi()
      .unwrap()
      .then((res) => {
        setAdminList(res?.admins);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProjectList();
  }, [page]);

  useEffect(() => {
    getViewAdmin();
  }, []);

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const closeToggle = () => {
    setAddProject(!addProject);
    setpresentation_style(0);
    setpresentation_styleErr(false);
    setProjectData({});
    setProjectViewData({});
  };

  const handleClick = (item) => {
    dispatch(saveLoader(true));
    presentationProViewApi(item?.id)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        if (res?.status == "success") {
          let productDistributorPrice =
            res?.productPrice?.productDistributorPrice;
          // let percent_margin;

          // if (item?.project_list?.status == 5) {
          let percent_margin = item?.margin_percent;
          // } else {
          //   percent_margin = item?.projectPresentationPrice?.[0]?.margin;
          // }

          let obj = {
            for_25: productDistributorPrice?.for_25,
            for_50: productDistributorPrice?.for_50,
            for_100: productDistributorPrice?.for_100,
            for_150: productDistributorPrice?.for_150,
            for_250: productDistributorPrice?.for_250,
            for_500: productDistributorPrice?.for_500,
            for_1000: productDistributorPrice?.for_1000,
          };

          let prices = Object.keys(obj);
          for (let i = 0; i < prices?.length; i++) {
            let percen = (+percent_margin / 100) * +obj[prices[i]];
            let sum = Number(+percen + +obj[prices[i]]).toFixed();
            setReqPrice((val) => ({ ...val, [prices[i]]: +sum }));
          }

          let quantity = res?.project_detail?.qty;
          let value = 25;

          if (+quantity >= 1 && +quantity <= 25) {
            value = 25;
          } else if (+quantity >= 26 && +quantity <= 50) {
            value = 50;
          } else if (+quantity >= 51 && +quantity <= 100) {
            value = 100;
          } else if (+quantity >= 101 && +quantity <= 150) {
            value = 150;
          } else if (+quantity >= 151 && +quantity <= 250) {
            value = 250;
          } else if (+quantity >= 251 && +quantity <= 500) {
            value = 500;
          } else if (+quantity >= 501 && +quantity <= 1000) {
            value = 1000;
          } else {
            value = 1000;
          }
          setproduct_qty(value);
          setmargin_percent(percent_margin);
          setProjectData(item);
          setProjectViewData(res);
          setAfterDiscount(res?.project_detail?.after_discount);
          if (res?.project_detail?.after_discount) {
            let discountPercentage =
              ((+res?.project_detail?.price_per_pack -
                res?.project_detail?.after_discount) /
                +res?.project_detail?.price_per_pack) *
              100;
            let discountPercentage_ = Number(discountPercentage).toFixed(2);
            setBeforeDiscount(discountPercentage_);
          }

          setAddProject(true);
        }
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  const submitHandle = () => {
    // if (+margin_percent >= +projectViewData?.productPrice?.msp_percentage) {
    let formdata = new FormData();
    formdata.append("project_detail_id", projectData?.id);
    formdata.append("margin", margin_percent);
    formdata.append("after_discount", afterDiscount);
    formdata.append(
      "net_price",
      Number(+projectViewData?.project_detail?.qty * +afterDiscount).toFixed(2)
    );

    if (beforeDiscount) {
      let price_per_pack = projectViewData?.project_detail?.price_per_pack;
      let quantity = projectViewData?.project_detail?.qty;
      let dis_amount = (+price_per_pack * +beforeDiscount) / 100;
      let final_dis = +quantity * +dis_amount;
      formdata.append("discount_percent", +beforeDiscount);
      formdata.append("discount_amount", +final_dis);
    } else {
      formdata.append("discount_percent", 0);
      formdata.append("discount_amount", 0);
    }

    dispatch(saveLoader(true));
    setBtn(true);
    estimateMarginReqApi(formdata)
      .unwrap()
      .then((res) => {
        setBtn(false);
        dispatch(saveLoader(false));
        toast.success(res?.success);
        getProjectList();
        closeToggle();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setBtn(false);
        console.log("err", err);
      });
    // } else {
    //   toast.error("Given Amount is below MSP Price");
    // }
  };

  const handleDiscountPrice = (price2, type) => {
    let obj = projectViewData?.productPrice?.productDistributorPrice;
    let obj2 = projectViewData?.productPrice;
    let obj3 = projectViewData?.productPrice?.productMspPrice;

    let productDistributorPrice_ = obj?.for_25;
    let productPrice_ = obj2?.for_25;
    let productMspPrice_ = obj3?.for_25;
    let quantity = projectViewData?.project_detail?.qty;
    let price_per_pack = projectViewData?.project_detail?.price_per_pack;

    if (quantity >= 1 && quantity <= 25) {
      productDistributorPrice_ = obj?.for_25;
      productPrice_ = obj2?.for_25;
      productMspPrice_ = obj3?.for_25;
    } else if (quantity >= 26 && quantity <= 50) {
      productDistributorPrice_ = obj?.for_50;
      productPrice_ = obj2?.for_50;
      productMspPrice_ = obj3?.for_50;
    } else if (quantity >= 51 && quantity <= 100) {
      productDistributorPrice_ = obj?.for_100;
      productPrice_ = obj2?.for_100;
      productMspPrice_ = obj3?.for_100;
    } else if (quantity >= 101 && quantity <= 150) {
      productDistributorPrice_ = obj?.for_150;
      productPrice_ = obj2?.for_150;
      productMspPrice_ = obj3?.for_150;
    } else if (quantity >= 151 && quantity <= 250) {
      productDistributorPrice_ = obj?.for_250;
      productPrice_ = obj2?.for_250;
      productMspPrice_ = obj3?.for_250;
    } else if (quantity >= 251 && quantity <= 500) {
      productDistributorPrice_ = obj?.for_500;
      productPrice_ = obj2?.for_500;
      productMspPrice_ = obj3?.for_500;
    } else if (quantity >= 501 && quantity <= 1000) {
      productDistributorPrice_ = obj?.for_1000;
      productPrice_ = obj2?.for_1000;
      productMspPrice_ = obj3?.for_1000;
    } else {
      productDistributorPrice_ = obj?.for_1000;
      productPrice_ = obj2?.for_1000;
      productMspPrice_ = obj3?.for_1000;
    }

    let price_per_pack_ = price_per_pack ? +price_per_pack : 0;
    let total_amount = 0;
    let final_value = +price_per_pack - +price2;
    let afterDiscount_ = 0;
    let beforeDiscount_ = 0;

    if (type == "percentageToAmount") {
      let originalPrice = +price_per_pack;
      let discountPercentage = +price2;
      let discountedPrice = originalPrice * (1 - discountPercentage / 100);
      let discountedPrice_ = Number(discountedPrice).toFixed(2);
      afterDiscount_ = +discountedPrice_;
      beforeDiscount_ = +discountPercentage;
      total_amount = +discountedPrice_ * +quantity;
    } else if (type == "amountToPercentage") {
      let originalPrice = +price_per_pack;
      let discountedPrice = +price2;
      let discountPercentage =
        ((originalPrice - discountedPrice) / originalPrice) * 100;
      let discountPercentage_ = Number(discountPercentage).toFixed(2);
      afterDiscount_ = +discountedPrice;
      beforeDiscount_ = +discountPercentage_;
      total_amount = +discountedPrice * +quantity;
    }

    let c11 = +afterDiscount_;
    // let a11 = +productMspPrice_;
    let a11 = +productDistributorPrice_;
    let b11 = ((c11 - a11) * 100) / a11;
    let margin_ = Number(b11).toFixed(2);
    let base_price = Number(total_amount).toFixed(2);

    setAfterDiscount(afterDiscount_);
    setBeforeDiscount(beforeDiscount_);
    setmargin_percent(Number(+margin_).toFixed(2));
  };

  return (
    <>
      <div className="dashRightView  p-5 home_section trans">
        <ProductSearchHeader />
        <div className="d-flex ac-jb flex-wrap rk2">
          <p className="f4 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 primary ">
            Projects Request Price
          </p>
          <button
            className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded`}
            onClick={() =>
              // navigate(`/presentation?project=${location?.state?.data}`)
              navigate(-1)
            }
          >
            Back
          </button>
        </div>

        <div className="mt-4 ">
          <div className="d-flex ac-jb flex-column flex-md-row">
            <div className=" d-flex ac-jc place-holde justify-content-md-start gap-3 flex-wrap w-100 w-md-90 mb-md-0 mb-4">
              <input
                type="text"
                className="w-20 get-start-btn respon-width  bg-lt-blue primary1 border-0 my-0 py-4 px-3"
                placeholder="Search for project"
                onChange={(e) =>
                  getProjectList(
                    e.target.value,
                    rows,
                    orders,
                    order_reps,
                    client_reps,
                    statuss,
                    codes,
                    production_reps
                  )
                }
                value={searchs}
              />
              <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
                <select
                  className="bg-transparent border-0 py-3 primary1"
                  onChange={(e) =>
                    getProjectList(
                      searchs,
                      rows,
                      orders,
                      order_reps,
                      e.target.value,
                      statuss,
                      codes,
                      production_reps
                    )
                  }
                  value={client_reps}
                >
                  <option disabled selected value="">
                    Client Rep
                  </option>
                  {adminList?.map((item, ind) => {
                    return (
                      <option value={item?.id} key={ind}>
                        {item?.first_name}
                      </option>
                    );
                  })}
                </select>
              </button>
              <button className="w-20 get-start-btn respon-width bg-lt-blue primary1 border-0 my-0 py-4">
                <select
                  className="bg-transparent border-0 py-3 primary1"
                  onChange={(e) =>
                    getProjectList(
                      searchs,
                      rows,
                      orders,
                      e.target.value,
                      client_reps,
                      statuss,
                      codes,
                      production_reps
                    )
                  }
                  value={order_reps}
                >
                  <option disabled selected value="">
                    Order Rep
                  </option>
                  {adminList?.map((item, ind) => {
                    return (
                      <option value={item?.id} key={ind}>
                        {item?.first_name}
                      </option>
                    );
                  })}
                </select>
              </button>
            </div>
            <div className="d-flex ac-jc gap-3">
              <button
                className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-4 rounded"
                onClick={() => getProjectList("", 10, "", "", "", "", "", "")}
              >
                <CachedIcon />
              </button>
            </div>
          </div>
          <div className="w-95 ac-jb d-flex flex-md-row flex-column mt-4">
            <div className="d-flex">
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15">
                Show
              </p>
              <select
                className="cust-btn f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 primary"
                onChange={(e) =>
                  getProjectList(
                    searchs,
                    e.target.value,
                    orders,
                    order_reps,
                    client_reps,
                    statuss,
                    codes,
                    production_reps
                  )
                }
                value={rows}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1">
                Entries
              </p>
            </div>
            <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-13 fs-xl-14 fs-xxl-15 tot-cont">
              Total Count : {list?.length > 0 ? list?.length : 0}
            </p>
          </div>
          <div className="mt-5" style={{ width: "100%", overflow: "auto" }}>
            <table className="table-design-new" style={{ width: "100%" }}>
              <thead>
                <tr className="border-bottom w-100 text-nowrap text-center">
                  <th>
                    <p className="w-100 f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-15 d-flex as-jc">
                      S.No
                    </p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Project ID</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Request ID</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Order Rep</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Client Rep</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Project Value</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Price Per Pack</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Discount</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Discount Price Per Pack</p>
                  </th>
                  <th>
                    <p className="f2 fs-14">Action</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => {
                  let dis_without_percen = "-";
                  let before_without_price = item?.retail_price;
                  if (item?.after_discount > 0) {
                    let discountPercentage =
                      ((+item?.price_per_pack - +item?.after_discount) /
                        +item?.price_per_pack) *
                      100;
                    dis_without_percen =
                      Number(discountPercentage).toFixed(2) + "%";
                  }
                  return (
                    <tr className="text-center">
                      <td>
                        <p className="pointerView f2 fs-15 fs-xs-16 fs-sm-16 fs-md-16 fs-lg-16 fs-xl-16 fs-xxl-16 d-flex ac-jc primary1">
                          {index + 1}
                        </p>
                      </td>
                      <td className="text-center">
                        <Link
                          className="f3 primary1 text-center"
                          to={`/overview?project=${item?.project_list?.project_code}_${item?.project_list?.id}`}
                          target="_blank"
                        >
                          {item?.project_list?.project_code}
                        </Link>
                      </td>
                      <td>
                        {
                          item?.project_list?.project_request
                            ?.project_request_id
                        }
                      </td>
                      <td>
                        {item?.project_list?.project_request?.order_rep
                          ?.first_name
                          ? item?.project_list?.project_request?.order_rep
                              ?.first_name
                          : "-"}
                      </td>
                      <td>
                        {item?.project_list?.project_request?.client_rep
                          ?.first_name
                          ? item?.project_list?.project_request?.client_rep
                              ?.first_name
                          : "-"}
                      </td>
                      <td>{item?.project_list?.total_amount}</td>
                      <td>{item?.price_per_pack}</td>
                      <td>{dis_without_percen}</td>
                      <td>
                        {item?.after_discount > 0 ? item?.after_discount : "-"}
                      </td>
                      <td>
                        {" "}
                        <div
                          className="viewBoxDes p-2 mb-2 bg-white rounded-2"
                          onClick={() => handleClick(item)}
                        >
                          <img src={viewIcon} className="viewDes" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* <div className="d-flex justify-content-between mt-3">
            <button
              className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                page == 1 && "opacity-50"
              }`}
              onClick={() => setPage(page - 1)}
              disabled={page == 1 ? true : false}
            >
              Previous
            </button>
            <button
              className={`cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                page == pagination?.last_page && "opacity-50"
              }`}
              onClick={() => setPage(page + 1)}
              disabled={page == pagination?.last_page ? true : false}
            >
              Next
            </button>
          </div> */}
        </div>
      </div>

      {addProject && (
        <div className="add-category-popup">
          <div>
            <p className="d-flex ac-jb mt-2 f4 fs-xs-15 fs-sm-16 fs-md-17 fs-lg-18 fs-xl-19 fs-xxl-20 primary">
              Request Price Update
              <span>
                <button onClick={() => closeToggle()} className="cust-btn">
                  <HighlightOffIcon />
                </button>
              </span>
            </p>

            <div className="w-100">
              <p
                className="w-100 f4 fs-xs-15 fs-sm-16 fs-md-18 fs-lg-20 fs-xl-22 fs-xxl-24 primary1 text-center mt-4 mb-4"
                // style={{ marginLeft: "0px" }}
              >
                {projectData?.product?.name}
              </p>
              <div className="d-flex mt-2 mx-3">
                <div className="justify-content-center d-flex flex-column gap-3 w-100">
                  <div className="d-flex justify-content-between w-100 mb-5">
                    <div>
                      <span className="f3 f1 fs-12 fs-lg-13 fs-xl-15 fs-xxl-18 mb-2 mt-2">
                        MSP
                      </span>
                      <div className="d-flex flex-column">
                        <div
                          className="mt-2 w-100"
                          style={{ display: "inline-block" }}
                        >
                          <div className="d-flex w-100">
                            <div className="mx-2 d-flex flex-column">
                              <span className="f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                Qty
                              </span>
                              <span className="mt-2 f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                Margin
                              </span>
                              <span className="mt-2 f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                Price
                              </span>
                            </div>
                            <div className="mx-2 d-flex flex-column">
                              <span className="f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                {projectViewData?.project_detail?.qty}
                              </span>
                              <span className="mt-2 f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                {projectViewData?.productPrice?.msp_percentage}
                              </span>
                              <span className="mt-2 f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                {
                                  projectViewData?.productPrice
                                    ?.productMspPrice?.[`for_${product_qty}`]
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="f3 f1 fs-12 fs-lg-13 fs-xl-15 fs-xxl-18 mb-2 mt-2">
                        SCP
                      </span>

                      <div className="d-flex flex-column">
                        <div
                          className="mt-2 w-100"
                          style={{ display: "inline-block" }}
                        >
                          <div className="d-flex w-100">
                            <div className="mx-2 d-flex flex-column">
                              <span className="f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                Qty
                              </span>
                              <span className="mt-2 f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                Margin
                              </span>
                              <span className="mt-2 f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                Price
                              </span>
                            </div>
                            <div className="mx-2 d-flex flex-column">
                              <span className="f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                {projectViewData?.project_detail?.qty}
                              </span>
                              <span className="mt-2 f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                {projectViewData?.productPrice?.scp_percentage}
                              </span>
                              <span className="mt-2 f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                                {
                                  projectViewData?.productPrice?.[
                                    `for_${product_qty}`
                                  ]
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span className="f3 f1 fs-15 fs-lg-16 fs-xl-18 fs-xxl-20 mb-2 mt-2 mb-3">
                      Requested Price
                    </span>
                    <div className="d-flex gap-3 mt-3">
                      <div
                        className="d-flex ac-jc flex-column position-relative"
                        style={{ width: "150px" }}
                      >
                        <span className="f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                          Qty
                        </span>
                        <input
                          placeholder="Required Field"
                          className="editBtn w-100 text-center rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust opacity-75"
                          type={"number"}
                          value={projectViewData?.project_detail?.qty}
                          readOnly
                        />
                      </div>
                      <div className="d-flex ac-jc flex-column position-relative">
                        <span className="f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                          Margin
                        </span>
                        <div
                          className="d-flex ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 opacity-75"
                            type={"number"}
                            value={margin_percent}
                            readOnly
                          />
                          <button className="drop_down4 cust-btn">
                            <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                              %
                            </p>
                          </button>
                          {margin_percent?.length == 0 &&
                            margin_percentErr &&
                            presentation_style == "request" && (
                              <div className="d-flex gap-1 mt-2">
                                <ErrorIcon className="svg_log" />
                                <p className="err-input_log mt-1">
                                  Enter Margin
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-3 mt-5">
                      <div className="d-flex ac-jc flex-column position-relative">
                        <span className="f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                          Discount Margin
                        </span>

                        <div
                          className="d-flex ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2 "
                            type={"number"}
                            value={beforeDiscount}
                            onChange={(e) =>
                              handleDiscountPrice(
                                e.target.value,
                                "percentageToAmount"
                              )
                            }
                          />
                          <button className="drop_down4 cust-btn">
                            <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                              <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                            </p>
                          </button>
                        </div>
                      </div>
                      <div className="d-flex ac-jc flex-column position-relative">
                        <span className="f2 f1 fs-14 fs-lg-15 fs-xl-16 fs-xxl-18">
                          After Discount
                        </span>
                        <div
                          className="d-flex ac-jc position-relative"
                          style={{ width: "150px" }}
                        >
                          <input
                            placeholder="Required Field"
                            className="editBtn text-end w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust2"
                            type={"number"}
                            value={afterDiscount}
                            onChange={(e) =>
                              handleDiscountPrice(
                                e.target.value,
                                "amountToPercentage"
                              )
                            }
                          />
                          <button className="drop_down4 cust-btn">
                            <p className="f5 fs-xs-15 fs-sm-15 fs-md-15 fs-lg-15 fs-xl-15 fs-xxl-15 text-center black">
                              <CurrencyRupeeIcon className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center black" />
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {presentation_style == 0 && presentation_styleErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Margin</p>
                </div>
              )}
            </div>

            <div className="d-flex mt-3 justify-content-end m-2">
              <button
                disabled={btn}
                className={`mt-2 cust-btn addbtn ms-1 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded ${
                  btn && "opacity-50"
                }`}
                onClick={() => submitHandle()}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectsRequestPrice;
